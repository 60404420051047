import React from 'react';
import { useParams,  useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useAuth } from "react-oidc-context";

import { useAlerts } from '../../context/AlertsContext';
import Post from '../../components/posts/Post';
import WisucineAPI from '../../api/WisucineAPI';
import PostForm from '../../components/posts/PostForm';
import CommentCard from '../../components/cards/CommentCard';
import CreateCommentCard from '../../components/cards/CreateCommentCard';

const commentsContent = [
    {
        _id: "toto",
        author: {
           name: "Roland Kayova",
           avatar: "https://eu.ui-avatars.com/api/?name=R+K&size=250",
        },
        createdAt: "8 février, 2024",
        content: "C'est un vrai fléau",
    },
    {
        _id: "tata",
        author: {
           name: "Toto",
           avatar: "https://eu.ui-avatars.com/api/?name=T+T&size=250",
        },
        createdAt: "8 février, 2024",
        content: "Ah oui ?",
        parentCommentId: "toto",
    }
]

const PostPage = () => {

    let { id } = useParams();
    const auth = useAuth();
    const { addAlert } = useAlerts();
    let navigate = useNavigate();

    const [post, setPost] = useState({});
    const [modeEdit, setModeEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        WisucineAPI
            .get('posts/' + id)
            .then((response) => {
                console.log(response.data);
                setPost(response.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => setIsLoading(false));
    }, [id, modeEdit])

    const handleLike = async (id) => {
        addAlert({ severity: 'success', message: 'Like ' + id, timeout: 3000 });
    }

    const handleReply = async (id) => {
        addAlert({ severity: 'success', message: 'Reply ' + id, timeout: 3000 });
    }

    const handlePut = async (formData) => {
        try {
            const response = await WisucineAPI.put('posts/' + formData._id, formData, {
                headers: { Authorization: `Bearer ${auth.user?.access_token}` }
            });
            console.log('Form data submitted successfully:', response.data);
            addAlert({ severity: 'success', message: 'Annonce mis a jour avec succès', timeout: 1000 });
            setModeEdit(false)
        } catch (error) {
            console.error('Error submitting form data:', error);
            addAlert({ severity: 'error', message: 'Erreur lors de la mise a jour de l\'annonce', timeout: 5000 });
        }

    };

    const handleDelete = async (e) => {
        try {
            const response = await WisucineAPI.delete('posts/' + id, {
                headers: { Authorization: `Bearer ${auth.user?.access_token}` }
            });
            console.log('Form data submitted successfully:', response.data);
            addAlert({ severity: 'success', message: 'Annonce supprimée avec succès', timeout: 1000 });
            navigate('/myposts');
        } catch (error) {
            console.error('Error submitting form data:', error);
            addAlert({ severity: 'success', message: 'Erreur lors de la suppression de l\'annonce', timeout: 5000 });
        }
    }

    if (isLoading) return <TailSpin visible={true} height="80" width="80" color="#d4a55c" radius="1" wrapperClass="my-20 flex-col items-center" />

    return (
        <div className='container mx-auto flex flex-col items-center'>
            { modeEdit ? 
                <PostForm title="Modifier la Publication" post={ post } onclickSubmit={handlePut} onClickCancel={() => setModeEdit(false)}/> 
                : 
                <div className='p-2'>
                    <Post post={ post } onClickEdit={() => setModeEdit(true)} onClickDelete={handleDelete} />
                    <div className='pt-2'>
                        <CreateCommentCard onclickSubmit={handleReply} />
                    </div>
                    {
                        commentsContent.map((comment) => (
                            <div className={`pt-2 ${comment.parentCommentId ? 'pl-8' : null}`}>
                                <CommentCard comment={comment} onClickLike={() => handleLike(comment._id)} onClickReply={() => handleReply(comment._id)}/>
                            </div>
                        ))
                    }
                    
                </div>
            }
        </div>
        
    );

};

export default PostPage;