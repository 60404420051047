import Carousel from '../components/Carousel'
import ModOp from '../components/ModOp'
import WhoAreUs from '../components/WhoAreUs'
import Annonces from '../components/Annonces'
import Pricing from '../components/pricing'
import OurTeam from '../components/OurTeam'
import Trust from '../components/Trust'
import Activity from '../components/Activity'

const App = () => {
   
  return (  
    <div>
      <div><Carousel/></div>
      <div><Annonces/></div>
      <div><ModOp/></div>
      <div><WhoAreUs/></div>
      <div><Pricing/></div>
      <div><Activity/></div>
      <div><Trust/></div>
      <div><OurTeam/></div> 
    </div>
  )
}
export default App