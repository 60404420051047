import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { TailSpin } from 'react-loader-spinner';

import { useAlerts } from '../context/AlertsContext';
import UserForm from '../components/UserForm';
import WisucineAPI from '../api/WisucineAPI';

const UserPage = () => {

    const auth = useAuth();
    const { addAlert } = useAlerts();
    let navigate = useNavigate();

    const [user, setUser] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        getUser()
        .then((response) => {
            setUser(response.data);
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => setIsLoading(false));
    }, [])

    const handleSubmit = async (formData) => {

        const config = {
            headers: { Authorization: `Bearer ${auth.user?.access_token}` }
        };

        try {
            const response = await WisucineAPI.put('users/' + auth.user?.profile?.sub, formData, config);

            console.log('Form data submitted successfully:', response.data);

            addAlert({ severity: 'success', message: 'L\'utilisateur est mis à jour avec succès', timeout: 1000 });
            //navigate('/annonce/' + response.data?.newAd?.id);
        } catch (error) {
            console.error('Error submitting form data:', error);
            addAlert({ severity: 'error', message: 'Erreur lors de la modification de l\'utilisateur', timeout: 5000 });
        }
    };

    const getUser = async () => {

        const config = {
            headers: { Authorization: `Bearer ${auth.user?.access_token}` }
        };

        try {
            const response = await WisucineAPI.get('users/' + auth.user?.profile?.sub, config);

            console.log('successfully:', response.data);

            return response
        } catch (error) {
            console.error('Error submitting form data:', error);
            addAlert({ severity: 'error', message: 'Erreur lors de la récupération de l\'utilisateur', timeout: 5000 });
        }
    };

    if (isLoading) return <TailSpin visible={true} height="80" width="80" color="#d4a55c" radius="1" wrapperClass="my-20 flex-col items-center" />

    return (
        <UserForm user={user} onclickSubmit={handleSubmit} onClickCancel={() => navigate('/')} />
    );

};

export default UserPage;
