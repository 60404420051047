import '../index.css';
import logo from '../assets/image/logo.jpg';
import { useAuth } from "react-oidc-context";



export default function Footer() {
  const auth = useAuth();
    return (
        

        
        <div class="w-full bg-white">
            

            

        <footer class="w-full text-gray-700 bg-black body-font">


             <div class="text-center">
  

  
<button
  type="button"
  data-twe-ripple-init
  data-twe-ripple-color="light"
  class="mb-2 inline-block rounded bg-black px-6 py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg">
  <span class="[&>svg]:h-6 [&>svg]:w-6">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 320 512">
       
      <path
        d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
    </svg>
  </span>
</button>

 
<button
  type="button"
  data-twe-ripple-init
  data-twe-ripple-color="light"
  class="mb-2 inline-block rounded bg-black px-6 py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg">
  <span class="[&>svg]:h-6 [&>svg]:w-6">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 448 512">
      
      <path
        d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
    </svg>
  </span>
</button>

 
<button
  type="button"
  data-twe-ripple-init
  data-twe-ripple-color="light"
  class="mb-2 inline-block rounded bg-black px-6 py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg">
  <span class="[&>svg]:h-6 [&>svg]:w-6">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 488 512">
      
      <path
        d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
    </svg>
  </span>
</button>

 
<button
  type="button"
  data-twe-ripple-init
  data-twe-ripple-color="light"
  class="mb-2 inline-block rounded bg-black px-6 py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg">
  <span class="[&>svg]:h-6 [&>svg]:w-6">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 448 512">
       
      <path
        d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
    </svg>
  </span>
</button>

 

 
 
<button
  type="button"
  data-twe-ripple-init
  data-twe-ripple-color="light"
  class="mb-2 inline-block rounded bg-black px-6 py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg">
  <span class="[&>svg]:h-6 [&>svg]:w-6">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 576 512">
       
      <path
        d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z" />
    </svg>
  </span>
</button>

 

 

 

 
 
       </div> 
            <div
                class="container  px-5 py-10 mx-auto md:items-center lg:items-start md:flex-row md:flex-no-wrap">
                <div class="flex-shrink-0 w-64 mx-auto text-center md:mx-0 md:text-left ">
                    <a href="/" class="flex items-center justify-center font-medium text-white title-font md:justify-start">
                    <img src={logo} height={200} width={200} />
                    </a>
                     
                    
                </div>
                
                <div class="flex flex-wrap flex-grow mt-10 -mb-10 text-center md:pl-20 md:mt-0 md:text-left">
                <span>  <div class="mr-20">
                        <h2 class="mb-3 text-sm font-medium tracking-widest text-customgold uppercase title-font">WISUCINE</h2>
                        <nav class="mb-10 w-full list-none">
                            <li class="mt-3">
                                <a href="/qui-sommes-nous" class="text-customgold cursor-pointer hover:text-white">Qui sommes nous</a>
                            </li>
                            <li class="mt-3">
                                <a href="/annonces" class="text-customgold cursor-pointer hover:text-white">Oportunités</a>
                            </li>
                            <li class="mt-3">
                                <a href="/nos-abonnements" class="text-customgold cursor-pointer hover:text-white">Nos offres personnalisées</a>
                            </li>
                            <li class="mt-3">
                                <a href="/activity" class="text-customgold cursor-pointer hover:text-white">Actualité</a>
                            </li>
                            <li class="mt-3">
                                <a class="text-customgold cursor-pointer hover:text-white">Evènements</a>
                            </li>
                             
                        </nav>
                    </div></span> <span>
                    <div class="mr-20 w-full px-4 lg:w-1/3 md:w-1/2">
                        <h2 class="mb-3 text-sm font-medium tracking-widest text-customgold uppercase title-font">Plateforme
                        </h2>
                        <nav class="mb-10 list-none">
                            <li class="mt-3">{!auth.isAuthenticated && (
            <button class=" px-3 md:px-4 py-1 md:py-2 bg-black-600   text-customgold rounded-lg hover:text-white" type="button" onClick={() => void auth.signinRedirect()}>
                Connexion
            
            </button>
            )}
                            </li>
                            <li class="mt-3">{!auth.isAuthenticated && (
            <button class=" px-3 md:px-4 py-1 md:py-2 bg-black-600   text-customgold rounded-lg hover:text-white" type="button" onClick={() => void auth.signinRedirect()}>
                Inscription
            
            </button>
            )}
                            </li>
                             
                        </nav>
                    </div>
                    </span>
                    <span>
                    <div class="w-full px-4 lg:w-1/4 md:w-1/2">
                        <h2 class="mb-3 text-sm font-medium tracking-widest text-customgold uppercase title-font">Information
                        </h2>
                        <nav class="mb-10 list-none">
                            <li class="mt-3">
                                <a href="/cgu" class="text-customgold cursor-pointer hover:text-white">CGU</a>
                            </li>
                            <li class="mt-3">
                                <a class="text-customgold cursor-pointer hover:text-white">CGA</a>
                            </li>
                            <li class="mt-3">
                                <a href="/politique-de-confidentialité" class="text-customgold cursor-pointer hover:text-white">Politique de confidentialité</a>
                            </li>
                            <li class="mt-3">
                                <a href="/mentions-légales" class="text-customgold cursor-pointer hover:text-white">Mentions légales</a>
                            </li>
                        </nav>
                    </div>
                    </span>
                    <span>
                    <div class="w-full px-4">
                        <h2 class="mb-3 text-sm font-medium tracking-widest text-customgold uppercase title-font">Contact
                        </h2>
                        <nav class="list-none">
                        
                        <li class="flex ">
                            <div class="flex h-10 w-10 items-center justify-center rounded bg-customgold text-gray-50">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="h-8 w-8">
                                    <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                                    <path
                                        d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"></path></svg>
                            </div>
                            <div class="ml-4 mb-4 text-customgold ">
                                <h3 class="mb-2 text-lg font-medium leading-6 text-customgold dark:text-white">Adresse</h3>
                                 
                                <p class="text-customgold ">5 place messager</p>
                                
                                
                                <p class="text-customgold dark:text-slate-400">95400 Villiers Le Bel, FRANCE</p>
                                
                            </div>
                        </li>
                        <li class="flex">
                            <div class="flex h-10 w-10 items-center justify-center rounded bg-customgold text-gray-50">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="h-6 w-6">
                                    <path
                                        d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2">
                                    </path>
                                    <path d="M15 7a2 2 0 0 1 2 2"></path>
                                    <path d="M15 3a6 6 0 0 1 6 6"></path>
                                </svg>
                            </div>
                            <div class="ml-4 mb-4">
                                <h3 class="mb-2 text-lg font-medium leading-6 text-customgold dark:text-white">Téléphone</h3>
                                <p class="text-customgold dark:text-slate-400">0658531706</p>
                                
                            </div>
                        </li>
                        <li class="flex">
                            <div class="flex h-10 w-10 items-center justify-center rounded bg-customgold text-gray-50">
                            <svg class="w-[24px] h-[24px] fill-[#ffffff]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

 
<path d="M256 64C150 64 64 150 64 256s86 192 192 192c17.7 0 32 14.3 32 32s-14.3 32-32 32C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256v32c0 53-43 96-96 96c-29.3 0-55.6-13.2-73.2-33.9C320 371.1 289.5 384 256 384c-70.7 0-128-57.3-128-128s57.3-128 128-128c27.9 0 53.7 8.9 74.7 24.1c5.7-5 13.1-8.1 21.3-8.1c17.7 0 32 14.3 32 32v80 32c0 17.7 14.3 32 32 32s32-14.3 32-32V256c0-106-86-192-192-192zm64 192a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z"></path></svg>

</div>
                            <div class="ml-4 mb-4">
                                <h3 class="mb-2 text-lg font-medium leading-6 text-customgold dark:text-white">Mail</h3>
                                <p class="text-customgold dark:text-slate-400">contactequipe@filmdebanlieue.com</p>
                            </div>
                        </li>
                     
                        </nav>
                    </div>
                    </span>
                </div>
            </div>
            <div class="bg-black">
                <div class="container px-5 py-4 mx-auto">
                    <p class="text-sm text-white capitalize xl:text-center">© 2024 Tous droits reservés </p>
                </div>
            </div>
        </footer>
    
    </div>
    )
  }
