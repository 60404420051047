import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

const labelClass = "block text-gray-700 text-sm font-bold mb-2";
const inputClass = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5";
const selectClass = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5";

const profilIndividualsItems = [
    { isGroup: true, text: 'Particulier devant la caméra', value: [
        { text: 'Acteur', value: 'Acteur' },
        { text: 'Danseur', value: 'Danseur' },
    ] 
    },
    { isGroup: true, text: 'Particulier derrière la camera', value: [
        { text: 'Chef Operateur', value: 'Chef Operateur' },
        { text: 'Cadreur', value: 'Cadreur' },
        { text: 'Réalisateur', value: 'Réalisateur' },
        { text: 'Scénariste', value: 'Scénariste' },
        { text: 'Photographe', value: 'Photographe' },
        { text: 'Maquilleur', value: 'Maquilleur' },
        { text: 'Régisseur', value: 'Régisseur' },
        { text: 'Costumier', value: 'Costumier' },
    ] 
    },
    { text: 'Autres', value: 'Autres' },
];

const profilProItems = [
    { isGroup: true, text: 'Particulier devant la caméra', value: [
        { text: 'Acteur', value: 'Acteur' },
        { text: 'Danseur', value: 'Danseur' },
    ] 
    },
    { isGroup: true, text: 'Particulier derrière la camera', value: [
        { text: 'Chef Operateur', value: 'Chef Operateur' },
        { text: 'Cadreur', value: 'Cadreur' },
        { text: 'Réalisateur', value: 'Réalisateur' },
        { text: 'Scénariste', value: 'Scénariste' },
        { text: 'Photographe', value: 'Photographe' },
        { text: 'Maquilleur', value: 'Maquilleur' },
        { text: 'Régisseur', value: 'Régisseur' },
        { text: 'Costumier', value: 'Costumier' },
    ] 
    },
    { isGroup: true, text: 'Structure', value: [
        { text: 'Production / Agence Artistique', value: 'Production / Agence Artistique' },
        { text: 'Organisme / Collectif / Association', value: 'Organisme / Collectif / Association' },
        { text: 'Ecole / Centre de Formation', value: 'Ecole / Centre de Formation' },
        { text: 'Prestataire / Logistique', value: 'Prestataire / Logistique' },
    ] 
    },
    { text: 'Autres', value: 'Autres' },
];

export default function UserForm({ title = "Modifier son compte", user = {}, onclickSubmit, onClickCancel }) {
    
    const [isPro, setIsPro] = useState(user.isPro);

    const { register, handleSubmit } = useForm({
        defaultValues: {
            firstName: user.firstName,
            lastName: user.lastName,
            birthday: user.birthday?.substring(0,10),
            phoneNumber: user.phoneNumber,
            profileType: user.profileType,
        }
    });

    const onSubmit = (data) => {
        data.isPro = isPro
        data.imageUrl = `https://eu.ui-avatars.com/api/?name=${data.firstName}+${data.lastName}&size=250`
        console.log('yeah :', data)
        onclickSubmit(data)
    };

    return (

        <div class="container mx-auto mt-12 mb-4">
            <div class="flex flex-col items-center mb-1">
                <h1 className="text-3xl font-bold text-center text-customgold">
                     {title}
                </h1>
            </div>
            <div class="flex flex-col rounded-[20px] mx-auto p-4 bg-clip-border">
                <form onSubmit={handleSubmit(onSubmit)}>

                    <h2 className="text-xl font-bold text-center text-customgold">Je suis</h2>

                    <div class="flex justify-center">
                        <div class="p-3">
                            <div className={ (isPro ? "bg-customgold" : "bg-white hover:bg-gray-200" ) + " cursor-pointer border border-gray-700 py-2 px-4 rounded w-40 text-left text-black font-bold"} onClick={() => {setIsPro(true)}}>
                                Un Professionel
                            </div>
                        </div>
                        <div class="p-3">
                            <div className={ (isPro ? "bg-white hover:bg-gray-200" : "bg-customgold" ) + " cursor-pointer border border-gray-700 py-2 px-4 rounded w-40 text-left text-black font-bold"} onClick={() => {setIsPro(false)}}>
                                Un Particulier
                            </div>
                        </div>
                    </div>

                    <h2 className="text-xl font-bold text-center text-customgold">Informations personnelles</h2>

                    <div class="mb-4">
                        <label class={labelClass}>Prénom</label>
                        <input class={inputClass} type='text' {...register("firstName")}/>
                    </div>

                    <div class="mb-4">
                        <label class={labelClass}>Nom</label>
                        <input class={inputClass} type='text' {...register("lastName")}/>
                    </div>

                    <div class="mb-4">
                        <label class={labelClass}>Date de naissance</label>
                        <input class={inputClass} type='date' {...register("birthday")}/>
                    </div>

                    <div class="mb-4">
                        <label class={labelClass}>Téléphone</label>
                        <input class={inputClass} type='text' {...register("phoneNumber")}/>
                    </div>

                    <div class="mb-4">
                        <label class={labelClass}>Type de profil</label>
                        <select class={selectClass} {...register("profileType")}>
                            <option selected disabled>Choisir le profil</option>

                            { isPro ?
                            profilProItems.map(item => (
                                item.isGroup ?
                                <optgroup label={item.text}>
                                    {item.value.map(sitem => (
                                        <option value={sitem.value}>{sitem.text}</option>
                                    ))}
                                </optgroup>
                                :
                                <option value={item.value}>{item.text}</option>
                            ))
                            :
                            profilIndividualsItems.map(item => (
                                item.isGroup ?
                                <optgroup label={item.text}>
                                    {item.value.map(sitem => (
                                        <option value={sitem.value}>{sitem.text}</option>
                                    ))}
                                </optgroup>
                                :
                                <option value={item.value}>{item.text}</option>
                            ))
                            }
                        </select>
                    </div>

                    {
                        isPro ?
                        <>

                        <h2 className="text-xl font-bold text-center text-customgold">Informations sur la société</h2>

                        <div class="mb-4">
                            <label class={labelClass}>Nom de la société</label>
                            <input class={inputClass} type='text' {...register("companyName")}/>
                        </div>

                        <div class="mb-4">
                            <label class={labelClass}>Siret</label>
                            <input class={inputClass} type='text' {...register("siret")}/>
                        </div>

                        </>
                        :
                        null
                    }

                    <div class="mb-4 text-center">
                        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" type='submit'>Mettre a jour</button>
                        <button class="ml-10 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full" type='button' onClick={onClickCancel}>Supprimer compte</button>
                    </div> 
                </form>
            </div>  
        </div>  

    );

}
