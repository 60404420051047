import React, { useState, useEffect } from 'react';

import FeedCard from '../components/feeds/FeedCard';

const feedItems = [
    {
        header: {
            action: 'A publié',
            author: {
                name: "Roland",
                avatar: "https://eu.ui-avatars.com/api/?name=R+K&size=250",
                job: "Coach",
            },
            when: 'Il y a 20j'
        },
        body: {
            title: "Nouveau Film",
            image: "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
            content: "Vous pouvez regarder nos film ici",
            link: {
                title: "Regarder la video",
                link: "/film"
            },
            author: {
                name: "Roland",
                avatar: "https://images.unsplash.com/photo-1542156822-6924d1a71ace?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60",
                job: "Coach",
            },
        }
    },
    {
        header: {
            action: 'A transferer',
            author: {
                name: "Roland",
                avatar: "https://images.unsplash.com/photo-1542156822-6924d1a71ace?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60",
                job: "Coach",
            },
            when: 'Il y a 20j'
        },
        body: {
            title: "Lyft launching cross-platform service this week",
            image: "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
            categorie: "startups",
            content: "Like so many organizations these days, Autodesk is a company in transition. It was until recently a traditional boxed software company selling licenses. Yet its own business model disruption is only part of the story",
            link: {
               title: "Learn More",
               link: ""
            }
        }
    }
]

const FeedPage = () => {

    return (
        <div className='container mx-auto flex flex-col items-center'>
            {feedItems.map(feed => (
                <div className='py-2'>
                    <FeedCard header={feed.header} body={feed.body} />
                </div>
            ))}
        </div>
    );

};

export default FeedPage;
