import Streaming from '../components/Streaming'

const Film = () => {
   
  return (
    <div>
      <Streaming title="James Damont"/>
    </div>
  )
}
export default Film