
 
import '../index.css';

const Us = () => {
   
  return (
    <div>
         
<div class="mb-4 mt-4 bg-black">
    <h1 class=" text-3xl font-bold text-customgold ml-[40%]">Notre histoire</h1>

    <ul class="dark:text-white ml-[15%]">
  <li
    class="py-4 font-semibold text-white">
    Wisucine, une plateforme en ligne qui transforme la manière dont les professionnels et les passionnés du cinéma se connectent et collaborent.
  </li>
  <li
     class="py-4 font-semibold text-white">
    Fondée par une équipe passionnée de cinéma, Wisucine est née de la volonté de créer un espace dynamique où les talents du cinéma peuvent s&#39;épanouir, se rencontrer et donner vie à
leurs projets les plus audacieux.
  </li>
  <li
     class="py-4 font-semibold text-white">
    À travers une interface intuitive et des fonctionnalités puissantes, Wisucine offre aux professionnels de l&#39;industrie cinématographique la possibilité de découvrir de nouveaux
talents, de former des équipes créatives et de concrétiser leurs visions artistiques.
  </li>
  <li
     class="py-4 font-semibold text-white">
    Pour les amateurs de cinéma, Wisucine représente bien plus qu&#39;une simple plateforme : c&#39;est un véritable écosystème où la créativité est encouragée, où les rêves prennent vie et où les
collaborations fructueuses voient le jour.
  </li>
  <li
     class="py-4 font-semibold text-white">
    Rejoignez-nous sur Wisucine et faites partie d&#39;une communauté passionnée qui célèbre l&#39;art du cinéma, où chaque utilisateur a l&#39;opportunité de partager ses histoires, de trouver
l&#39;inspiration et de collaborer avec d&#39;autres passionnés du septième art.
  </li>
</ul>

</div> 
{/*nos valeurs*/}
<div class="valeurs">
 
<section >
    <h1 class=" mb-6 text-3xl font-bold text-customgold ml-[40%]">Nos valeurs</h1>

    <ul class=" dark:text-white ml-[15%] mb-6">
  <li
    class=" py-4 font-semibold">
    <b class="text-customgold" >Égalité :</b> Tous les membres sont traités avec respect et ont un accès équitable aux
opportunités.
  </li>
  <li
   class=" py-4 font-semibold">
    <b class="text-customgold" >Créativité :</b> Nous encourageons l&#39;innovation et la diversité artistique, offrant un espace où la
créativité est célébrée.
  </li>
  <li
   class=" py-4 font-semibold">
    <b class="text-customgold" >Local :</b> Wisucine met en lumière les talents locaux et soutient les industries
cinématographiques locales.
  </li>
  <li
   class=" py-4 font-semibold">
    <b class="text-customgold" >Jeunesse :</b> Nous investissons dans la prochaine génération de cinéastes en leur offrant des
opportunités et des ressources.
  </li>
  <li
   class=" py-4 font-semibold">
    <b class="text-customgold" >Inclusion :</b> Notre communauté est inclusive, valorisant la diversité sous toutes ses formes et
assurant que chaque voix soit entendue et respectée.
  </li>
</ul>
</section>
</div>

{/*nos services*/}
 
<div class=" bg-black py-10 mb-6">
    <h1 class="text-3xl font-bold text-customgold ml-[40%]">Nos services</h1>

    <ul class=" dark:text-white ml-[15%]">
  <li
    class="py-4 font-semibold text-white">
    Création d’offres d’emplois via des annonces et des appels à projets.
  </li>
  <li
    class="py-4 font-semibold text-white">
    Connexion entre les professionnels du cinéma, de l'audiovisuel et du spectacle pour créer des synergies culturelles dans la ville.
  </li>
  <li
    class="py-4 font-semibold text-white">
    Mise en relation avec des clients comme les centres socio-culturels,les écoles et les entreprises.
  </li>
  <li
    class="py-4 font-semibold text-white">
    Mise en lumière des talents locaux avec des événements où ils peuvent présenter leurs projets à des investisseurs et des organismes.
  </li>
   
</ul>
     
   </div>
    </div>
  )
}
export default Us