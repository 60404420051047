import React from 'react';

const CONFIDENTIALITE = () => {

 return (
   <div>
      <div class="container my-12 mx-auto px-2 md:px-4 border-[4px] border-customgold">

<section class="mb-32">

    <div class="flex justify-center">
        <div class="text-center md:max-w-xl lg:max-w-3xl">
            <h2 class="mb-12 px-6 text-2xl text-customgold font-bold">
            POLITIQUE DE CONFIDENTIALITE
            </h2>
        </div>
    </div>
  <ul>
  <div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            Introduction
            </h3>
        </div>
     <li class="py-4 font-semibold text-white">Bienvenue sur notre plateforme, un espace dédié aux annonces et au
réseautage dans les domaines du cinéma, de l&#39;audiovisuel et du spectacle.
Cette politique de confidentialité explique comment nous collectons,
utilisons, partageons et protégeons vos informations personnelles lorsque
vous utilisez notre plateforme.</li>
<div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            1. Collecte des Informations
            </h3>
        </div>
        <li class="font-semibold">Nous collectons différentes types d&#39;informations pour fournir et améliorer
        notre service :</li> 
        <li class="font-semibold"><b>- Informations que vous fournissez :</b> Lors de la création de votre
compte, de la publication d&#39;annonces ou de la candidature à des
missions, nous collectons des informations personnelles telles que
votre nom, votre adresse e-mail, votre numéro de téléphone, vos
qualifications professionnelles et d&#39;autres détails pertinents.</li>
        <li class="font-semibold"><b>- Informations collectées automatiquement :</b> Nous recueillons
automatiquement des informations sur votre utilisation de notre
plateforme, y compris votre adresse IP, le type de navigateur, les
pages visitées et les interactions avec notre site.</li>
        <li class="py-4 font-semibold"><b>-Informations provenant de tiers :</b> Nous pouvons recevoir des
informations vous concernant de la part de tiers si vous utilisez des
services connectés ou si ces tiers partagent ces informations avec
nous.</li>
<div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            2. Utilisation des Informations
            </h3>
        </div>
        <li class="font-semibold">- Les informations collectées sont utilisées pour :</li>
        <li class="font-semibold">- Fournir, maintenir et améliorer notre plateforme et nos services.</li>
        <li class="font-semibold">- Faciliter la publication d&#39;annonces, la candidature à des missions et la
        création de réseaux.</li>
        <li class="font-semibold">- Communiquer avec vous concernant votre compte, vos annonces et
        vos candidatures.</li>
        <li class="font-semibold">- Personnaliser votre expérience en fonction de vos affinités et
        préférences.</li>
        <li class="font-semibold">- Analyser l&#39;utilisation de notre plateforme pour en améliorer les
        fonctionnalités.</li>
        <div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            3. Partage des Informations
            </h3>
        </div>
        <li class="font-semibold">Nous partageons vos informations personnelles uniquement dans les cas
        suivants :</li>
        <li class="font-semibold">-<b> Avec votre consentement :</b> Nous partageons vos informations
        lorsque vous nous en donnez l&#39;autorisation.</li> 
        <li class="py-4 font-semibold">-<b> Avec d&#39;autres utilisateurs :</b> Vos informations peuvent être visibles
par d&#39;autres utilisateurs en fonction des paramètres de
confidentialité que vous choisissez.</li>
        <li class="py-4 font-semibold">-<b> Avec des prestataires de services :</b> Nous travaillons avec des
prestataires tiers qui nous aident à fournir, améliorer et maintenir
notre plateforme.</li>
        <li class="py-4 font-semibold">-<b> Pour des raisons légales :</b> Nous pouvons divulguer vos informations
si cela est nécessaire pour se conformer à une obligation légale ou
pour protéger nos droits, notre propriété ou notre sécurité, ainsi que
ceux de nos utilisateurs ou du public.</li>
<div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            4. Sécurité des Informations
            </h3>
        </div>
        <li class="py-4 font-semibold">Nous mettons en œuvre des mesures de sécurité pour protéger vos
informations personnelles contre l&#39;accès, l&#39;utilisation ou la divulgation non
autorisés. Cependant, aucune méthode de transmission ou de stockage
électronique n&#39;est totalement sécurisée. Nous nous efforçons de protéger
vos informations personnelles, mais nous ne pouvons garantir leur sécurité
absolue.</li>
<div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            5. Vos Droits
            </h3>
        </div>
        <li class="font-semibold">Vous avez le droit de :</li>
        <li class="font-semibold">- Accéder à vos informations personnelles et demander une copie.</li>
        <li class="font-semibold">- Demander la correction de toute information inexacte ou
        incomplète.</li>
        <li class="font-semibold">- Demander la suppression de vos informations personnelles.</li>
        <li class="font-semibold">- Vous opposer au traitement de vos informations personnelles ou en
        demander la limitation.</li> 
        <li class="py-4 font-semibold">- Retirer votre consentement à tout moment lorsque le traitement est
        basé sur votre consentement.</li> 
        <li class="font-semibold">Pour exercer vos droits, veuillez nous contacter à l&#39;adresse e-mail indiquée
        ci-dessous.</li>
        <li class="font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            6. Modifications de la Politique de Confidentialité
            </h3>
        </div></li>
        <li class="py-4 font-semibold">Nous nous réservons le droit de modifier cette politique de confidentialité à
tout moment. Les modifications seront publiées sur cette page avec une
date de mise à jour révisée. Nous vous encourageons à consulter
régulièrement cette page pour rester informé de la manière dont nous
protégeons vos informations.</li>
<li class="font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            7. Contact
            </h3>
        </div></li>
        <li class="font-semibold">Si vous avez des questions ou des préoccupations concernant cette
politique de confidentialité ou nos pratiques de confidentialité, veuillez
nous contacter à :</li>
        <li class="font-semibold"><b>Email :</b> contactequipe@filmdebanlieue.com</li>
        <li class="py-4 font-semibold"><b>Adresse :</b> 5 place messager,95400,Villiers-Le-Bel
Merci d&#39;utiliser notre plateforme. Nous nous engageons à protéger votre
vie privée et à fournir un environnement sécurisé pour vos activités
professionnelles dans le domaine du cinéma, de l&#39;audiovisuel et du
spectacle.</li>
         
  
  </ul>
</section>
</div>
   </div>
 );
};

export default CONFIDENTIALITE;