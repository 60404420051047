import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Nav from "./components/Nav";
import Footer from './components/Footer'
import HomePage from "./pages/Homepage";
import NoAuthorizePage from "./pages/NoAuthorizePage";
import ListAnnoncesPage from "./pages/annonces/ListAnnoncesPage";
import AnnoncePage from "./pages/annonces/Annoncepage";
import NoservicesPage from "./pages/NoservicesPage";
import Notrehistoirepage from "./pages/Notrehistoirepage";
import Nosvaleurspage from "./pages/Nosvaleurspage";
import Tablepricepage from "./pages/Tablepricepage";
import CGUpage from "./pages/CGUpage";
import Mentions from "./pages/MentionsLégalespage"
import Contactpage from "./pages/Contactpage";
import WhoAreUspage from "./pages/WhoAreUspage";
import CreateAnnoncePage from "./pages/annonces/CreateAnnoncepage";
import MyPostsPage from "./pages/posts/MyPostsPage";
import PolitiqueDeConfidentialitepage from "./pages/PolitiqueDeConfidentialitepage.js";
import FilmPage from "./pages/FilmPage";
import UserPage from "./pages/UserPage";
import FeeldActivityPage from "./pages/FeeldActivityPage";
import FeedPage from "./pages/FeedPage";
import { useAuth } from "react-oidc-context";
import { useEffect } from "react";
import { Collapse, Dropdown, initTWE } from "tw-elements";
import PostPage from "./pages/posts/PostPage.js";
import CreatePost from "./pages/posts/CreatePostPage.js";

function App() {
  const auth = useAuth();

  useEffect(() => {
    initTWE({ Collapse, Dropdown });   
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Nav/>
        <Routes>
          <Route exact path="/" element={auth.isAuthenticated ? <FeedPage /> : <HomePage />} />
          <Route path="/nos-services" element={<NoservicesPage />} />
          <Route path="/notre-histoire" element={<Notrehistoirepage />} />
          <Route path="/nos-valeurs" element={<Nosvaleurspage />} />
          <Route path="/nos-abonnements" element={<Tablepricepage />} />
          <Route path="/contact" element={<Contactpage />} />
          <Route path="/qui-sommes-nous" element={<WhoAreUspage />} />
          <Route path="/film" element={auth.isAuthenticated ? <FilmPage /> : <NoAuthorizePage />} />
          <Route path="/activity" element={auth.isAuthenticated ? <FeeldActivityPage /> : <NoAuthorizePage />} />
          <Route exact path="/annonce/:id" element={<AnnoncePage />} />
          <Route exact path="/annonce" element={ auth.isAuthenticated ? <CreateAnnoncePage /> : <NoAuthorizePage /> }/> 
          <Route exact path="/annonces" element={<ListAnnoncesPage />} />
          <Route exact path="/myposts" element={ auth.isAuthenticated ? <MyPostsPage /> : <NoAuthorizePage /> }/> 
          <Route exact path="/posts/:id" element={<PostPage />} />
          <Route exact path="/posts" element={<CreatePost />} />
          <Route exact path="/user" element={auth.isAuthenticated ? <UserPage /> : <NoAuthorizePage />} />
          <Route path="/cgu" element={<CGUpage />} />
          <Route path="/politique-de-confidentialité" element={<PolitiqueDeConfidentialitepage />} />
          <Route path="/mentions-légales" element={<Mentions />} />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}
 
export default App;