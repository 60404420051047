import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import InputFile from '../cards/InputFile';

const labelClass = "block text-gray-700 text-sm font-bold mb-2";
const inputClass = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5";
const selectClass = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5";

const postsProfile = [
    { text: 'Article', value: 'Article' },
    { text: 'Video', value: 'Video' },
]

export default function PostForm({ title = "Créer une Publication", post = {}, onclickSubmit, onClickCancel }) {

    const { register, handleSubmit } = useForm({
        defaultValues: {
            title: post.title,
            content: post.content,
        }
    });

    const [file, setFile] = useState(null);    

    const onSubmit = (data) => {
        if (post._id) data._id = post._id
        data.imageUrl = "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
        onclickSubmit(data)
    };

    return (

        <div class="mt-8 mb-2 max-w-screen-xlg container">
            <div class="flex flex-col items-center mb-1">
                <h1 className="text-3xl font-bold text-center text-customgold">
                     {title}
                </h1>
            </div>
            <div class="flex flex-col rounded-[20px] mx-auto p-4 bg-clip-border">
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div class="mb-4">
                        <label class={labelClass}>Type de publications</label>
                        <select class={selectClass} {...register("profilePost")}>
                            <option selected disabled>Choisir le profil</option>

                            {postsProfile.map(item => (
                                item.isGroup ?
                                <optgroup label={item.text}>
                                    {item.value.map(sitem => (
                                        <option value={sitem.value}>{sitem.text}</option>
                                    ))}
                                </optgroup>
                                :
                                <option value={item.value}>{item.text}</option>
                            ))
                            }
                        </select>
                    </div>

                    <div class="mb-4">
                        <div class="grid md:grid-cols-2 md:gap-6">
                            <InputFile onClickFile={(file) => setFile(file)}/>
                        </div> 

                        <div class="mb-1 flex flex-col gap-6">
                            <div class="mb-4">
                                <label class={labelClass}>Titre</label>
                                <input class={inputClass} type='text' {...register("title")}/>
                            </div>

                            <div class="mb-4">
                                <label class={labelClass}>Contenu</label>
                                <textarea class={inputClass} rows={8} {...register("content")}/>
                            </div>
                        </div>
                    </div>

                    {
                        post._id ?
                        <div class="mb-4 text-center">
                            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" type='submit'>Mettre a jour</button>
                            {onClickCancel ? <button class="ml-10 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full" type='button' onClick={onClickCancel}>Annuler</button> : null}
                        </div> 
                        :
                        <div class="mb-4 text-center">
                            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" type='submit'>Créer</button>
                            {onClickCancel ? <button class="ml-10 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full" type='button' onClick={onClickCancel}>Annuler</button> : null}
                        </div>
                    }
                     
                </form>
            </div>  
        </div>  

    );

}
