import React, { useState } from 'react';
import { Link } from "react-router-dom";
import {
   Avatar,
   Card,
   CardHeader,
   CardBody,
   CardFooter,
   Typography,
   Button,
 } from "@material-tailwind/react";

import { AiFillLike } from "react-icons/ai";
import { AiOutlineLike } from "react-icons/ai";
import { MdOutlineInsertComment } from "react-icons/md";

import CreateCommentCard from '../../components/cards/CreateCommentCard';

const commentContent = {
   author: {
      name: "Roland Kayova",
      avatar: "https://eu.ui-avatars.com/api/?name=R+K&size=250",
   },
   createdAt: "8 février, 2024",
   content: "C'est un vrai fléau"
}

export default function CommentCard({ comment = commentContent, onClickLike, onClickReply }) {

   const [like, setLike] = useState(false);
   const [showForm, setShowForm] = useState(false);

   function handlerLike() {
      setLike(!like)
      onClickLike()
   }

    return (
      <>
      <Card className="w-full max-w-[48rem]" shadow={true} >
         
         <CardHeader color="transparent" floated={false} shadow={false} className="mx-0 flex items-center gap-4 p-2 rounded-none border-b">
            <Avatar size="xs" variant="circular" alt="tania andrew" src={comment?.author?.avatar} />
            
            <div className="flex w-full flex-col gap-0.5">
               <div className="flex items-center justify-between">
                  <Typography variant="h6" color="blue-gray">{comment?.author?.name}</Typography>
                  <Typography variant="text" color="blue-gray">{comment?.createdAt}</Typography>
               </div>
            </div>
         </CardHeader>
         
         <CardBody>
            <Typography variant="text" color="gray" className="mb-4">{comment.content}</Typography>
         </CardBody>

         <CardFooter divider={true} className="p-0 flex">
            <Button onClick={handlerLike} className="flex justify-center gap-2" variant="text" >{like ? <AiFillLike /> : <AiOutlineLike />}J'aime</Button>
            <Button onClick={() => setShowForm(!showForm)} className="flex justify-center gap-2" variant="text"><MdOutlineInsertComment/>Répondre</Button>
         </CardFooter>
      </Card>

      {
         showForm ? 
         <CreateCommentCard onclickSubmit={onClickReply}/>
         : 
         null
      }
      </>
    );

};

