import React from "react";
import logo from '../assets/image/logo.jpg';
import Rolland from '../assets/image/Rolland.jpg'
import { useAuth } from "react-oidc-context";
import { Link } from "react-router-dom";
 
import {
  Collapse,
  Dropdown,
  initTWE,
} from "tw-elements";

initTWE({ Collapse, Dropdown });

const Nav = () => {
  const auth = useAuth();

 return (
   <div>
   

<nav
  class="relative flex w-full flex-nowrap items-center justify-between bg-black py-2 shadow-dark-mild dark:bg-neutral-700 lg:flex-wrap lg:justify-center lg:py-4"
  data-twe-navbar-ref>
  <div class="flex w-full flex-wrap items-center justify-between px-3">
    {/*<!-- Hamburger button for mobile view -->*/}
    <button
      class="block border-0 bg-transparent px-2 text-white hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 dark:text-neutral-200 lg:hidden"
      type="button"
      data-twe-collapse-init
      data-twe-target="#navbarSupportedContent5"
      aria-controls="navbarSupportedContent5"
      aria-expanded="false"
      aria-label="Toggle navigation">
      {/*<!-- Hamburger icon -->*/}
      <span
        class="[&>svg]:w-7 [&>svg]:stroke-black/50 dark:[&>svg]:stroke-neutral-200">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor">
          <path
            fill-rule="evenodd"
            d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
            clip-rule="evenodd" />
        </svg>
      </span>
    </button>

    {/*<!-- Collapsible navbar container -->*/}
    <div
      class="!visible mt-2 hidden flex-grow basis-[100%] items-center lg:mt-0 lg:!flex lg:basis-auto justify-center"
      id="navbarSupportedContent5"
      data-twe-collapse-item>
      {/*<!-- Left links -->*/}
       {/*<!-- Home link -->*/}
       <div class=" font-bold text-xl">
        <li>
           
           
            
       <Link to="/"><img src={logo} alt="logo" height={200} width={200} /></Link>
      
        </li></div>
      <ul
        class="list-style-none mt-6  flex flex-col ps-0 lg:mt-1 lg:flex-row gap-6"
        data-twe-navbar-nav-ref>
       

        {/*<!-- Link -->*/}
        <li
          class="mb-4 ps-2 lg:mb-0 lg:pe-1 lg:ps-0"
          data-twe-nav-item-ref>
          <a
            class="p-0 text-white transition duration-200 hover:text-customgold hover:ease-in-out focus:text-black/80 active:text-black/80 motion-reduce:transition-none dark:text-white/60 dark:hover:text-white/80 dark:focus:text-white/80 dark:active:text-white/80 lg:px-2"
            href="/qui-sommes-nous"
            data-twe-nav-link-ref
            >Qui sommes nous</a>
        </li>
         {/*<!-- Dropdown link -->*/}
         <li
          class="mb-4 ps-2 lg:mb-0 lg:pe-1 lg:ps-0"
          data-twe-nav-item-ref
          data-twe-dropdown-ref>
          {/*<!-- Dropdown -->*/}
          <a
            class="flex items-center text-white transition duration-200 hover:text-customgold hover:ease-in-out focus:text-white active:text-white motion-reduce:transition-none dark:text-white/60 dark:hover:text-white/80 dark:focus:text-white/80 dark:active:text-white/80 lg:px-2"
            href="#"
            type="button"
            id="dropdownMenuButton2"
            data-twe-dropdown-toggle-ref
            aria-expanded="false">
            Opportunités
            <span class="ms-1 [&>svg]:w-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor">
                <path
                  fill-rule="evenodd"
                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                  clip-rule="evenodd" />
              </svg>
            </span>
          </a>
          <ul
            class="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg data-[twe-dropdown-show]:block dark:bg-surface-dark"
            aria-labelledby="dropdownMenuButton1"
            data-twe-dropdown-menu-ref>
            <li>
              <a
                class="block w-full whitespace-nowrap bg-black px-4 py-2 text-sm font-normal text-white hover:bg-customgold focus:bg-zinc-200/60 focus:outline-none active:bg-zinc-200/60 active:no-underline dark:bg-surface-dark dark:text-white dark:hover:bg-neutral-800/25 dark:focus:bg-neutral-800/25 dark:active:bg-neutral-800/25"
                href="/annonces"
                data-twe-dropdown-item-ref
                >Consultation</a>
            </li>
            <li>
              <a
                class="block w-full whitespace-nowrap bg-black px-4 py-2 text-sm font-normal text-white hover:bg-customgold focus:bg-zinc-200/60 focus:outline-none active:bg-zinc-200/60 active:no-underline dark:bg-surface-dark dark:text-white dark:hover:bg-neutral-800/25 dark:focus:bg-neutral-800/25 dark:active:bg-neutral-800/25"
                href="/annonce"
                data-twe-dropdown-item-ref
                >Dépôt</a>
            </li>
            <li>
              <a
                class="block w-full whitespace-nowrap bg-black px-4 py-2 text-sm font-normal text-white hover:bg-customgold focus:bg-zinc-200/60 focus:outline-none active:bg-zinc-200/60 active:no-underline dark:bg-surface-dark dark:text-white dark:hover:bg-neutral-800/25 dark:focus:bg-neutral-800/25 dark:active:bg-neutral-800/25"
                href="#"
                data-twe-dropdown-item-ref
                >Appel à projet</a>
            </li>
          </ul>
        </li>
        
        {/*<!-- Link -->*/}
        <li
          class="mb-4 ps-2 lg:mb-0 lg:pe-1 lg:ps-0"
          data-twe-nav-item-ref>
          <a
            class="p-0 text-white transition duration-200 hover:text-customgold hover:ease-in-out focus:text-black/80 active:text-black/80 motion-reduce:transition-none dark:text-white/60 dark:hover:text-white/80 dark:focus:text-white/80 dark:active:text-white/80 lg:px-2"
            href="/film"
            data-twe-nav-link-ref
            >Le Film</a>
        </li>
       {/*<!-- Link -->*/}
       <li
          class="mb-4 ps-2 lg:mb-0 lg:pe-1 lg:ps-0"
          data-twe-nav-item-ref>
          <a
            class="p-0 text-white transition duration-200 hover:text-customgold hover:ease-in-out focus:text-black/80 active:text-black/80 motion-reduce:transition-none dark:text-white/60 dark:hover:text-white/80 dark:focus:text-white/80 dark:active:text-white/80 lg:px-2"
            href="#"
            data-twe-nav-link-ref
            >Nos offres personnalisées</a>
        </li>
        {/*<!-- Link -->*/}
        <li
          class="mb-4 ps-2 lg:mb-0 lg:pe-1 lg:ps-0"
          data-twe-nav-item-ref>
          <a
            class="p-0 text-white transition duration-200 hover:text-customgold hover:ease-in-out focus:text-black/80 active:text-black/80 motion-reduce:transition-none dark:text-white/60 dark:hover:text-white/80 dark:focus:text-white/80 dark:active:text-white/80 lg:px-2"
            href="/activity"
            data-twe-nav-link-ref
            >Actualité</a>
        </li>
        {/*<!-- Link -->*/}
        <li
          class="mb-4 ps-2 lg:mb-0 lg:pe-1 lg:ps-0"
          data-twe-nav-item-ref>
          <a
            class="p-0 text-white transition duration-200 hover:text-customgold hover:ease-in-out focus:text-black/80 active:text-black/80 motion-reduce:transition-none dark:text-white/60 dark:hover:text-white/80 dark:focus:text-white/80 dark:active:text-white/80 lg:px-2"
            href="#"
            data-twe-nav-link-ref
            >Evènement</a>
        </li>
        {/*<!-- Link -->*/}
        <li
          class="mb-4 ps-2 lg:mb-0 lg:pe-1 lg:ps-0"
          data-twe-nav-item-ref>
          <a
            class="p-0 text-white transition duration-200 hover:text-customgold hover:ease-in-out focus:text-black/80 active:text-black/80 motion-reduce:transition-none dark:text-white/60 dark:hover:text-white/80 dark:focus:text-white/80 dark:active:text-white/80 lg:px-2"
            href="/contact"
            data-twe-nav-link-ref
            >Contact</a>
        </li>

{/*utilisateur non connecté*/}
        {!auth.isAuthenticated && ( <div class="relative" data-twe-dropdown-ref>
  <a
    class=" flex items-center rounded bg-black px-6 pb-2 pt-1.0 text-xs font-medium uppercase leading-normal text-white  transition duration-150 ease-in-out hover:bg-customgold  focus:outline-none focus:ring-0  motion-reduce:transition-none dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
    href="#"
    type="button"
    id="dropdownMenuButton2"
    data-twe-dropdown-toggle-ref
    aria-expanded="false"
    data-twe-ripple-init
    data-twe-ripple-color="light">
    <svg class="w-[24px] h-[24px] fill-[#ffffff]" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg">

 
<path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L353.3 251.6C407.9 237 448 187.2 448 128C448 57.3 390.7 0 320 0C250.2 0 193.5 55.8 192 125.2L38.8 5.1zM264.3 304.3C170.5 309.4 96 387.2 96 482.3c0 16.4 13.3 29.7 29.7 29.7H514.3c3.9 0 7.6-.7 11-2.1l-261-205.6z"></path>

</svg>
    <span class="ms-2 w-2 [&>svg]:h-5 [&>svg]:w-5">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor">
        <path
          fill-rule="evenodd"
          d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
          clip-rule="evenodd" />
      </svg>
    </span>
  </a>
  <ul
    class="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-black bg-clip-padding text-base shadow-lg data-[twe-dropdown-show]:block dark:bg-surface-dark"
    aria-labelledby="dropdownMenuButton2"
    data-twe-dropdown-menu-ref>
    

                   <li class="flex">
                            <div class="flex h-10 w-10  items-center justify-center rounded bg-black text-white">
                            <svg class="w-[24px] h-[24px] fill-[#ffffff]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
<path d="M217.9 105.9L340.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L217.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1L32 320c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM352 416l64 0c17.7 0 32-14.3 32-32l0-256c0-17.7-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32l64 0c53 0 96 43 96 96l0 256c0 53-43 96-96 96l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32z"></path>

</svg>
</div>

{/*bouton connexion*/}
{!auth.isAuthenticated && (
            <button class=" py-1 md:py-2 bg-black-600  text-white rounded-lg hover:text-customgold" type="button" onClick={() => void auth.signinRedirect()}>
                Connexion
            </button>
            )}   
</li>   

<li class="flex">
                            <div class="flex h-10 w-10  items-center justify-center rounded bg-black text-white">
                            <svg class="w-[24px] h-[24px] fill-[#ffffff]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
<path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"></path>

</svg>
</div>
{/*bouton inscription*/}
{!auth.isAuthenticated && (
            <button class=" py-1 md:py-2 bg-black-600  text-white rounded-lg hover:text-customgold" type="button" onClick={() => void auth.signinRedirect()}>
                inscription
            </button>
            )}   
</li>
                            
  </ul>
</div>)}

{/*utilisateur connecté*/}


{!!auth.isAuthenticated && ( <div class="relative" data-twe-dropdown-ref>
  <a
    class="flex items-center rounded bg-black px-6 pb-2 pt-0.1 text-xs font-medium uppercase leading-normal text-white  transition duration-150 ease-in-out  focus:outline-none focus:ring-0  motion-reduce:transition-none dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
    href="#"
    type="button"
    id="dropdownMenuButton2"
    data-twe-dropdown-toggle-ref
    aria-expanded="false"
    data-twe-ripple-init
    data-twe-ripple-color="light">
    <div class="w-12 h-12 rounded-full"><img src={Rolland} class="rounded-full"></img></div>
    <span class="ms-2 w-2 [&>svg]:h-5 [&>svg]:w-5">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor">
        <path
          fill-rule="evenodd"
          d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
          clip-rule="evenodd" />
      </svg>
    </span>
  </a>
  <ul
    class="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-black bg-clip-padding text-base shadow-lg data-[twe-dropdown-show]:block dark:bg-surface-dark"
    aria-labelledby="dropdownMenuButton2"
    data-twe-dropdown-menu-ref>

<li class=" text-white text-center">

({auth.user?.profile.preferred_username}) 
    
</li>
  <li class="flex">
     <div class="flex h-10 w-10  items-center justify-center rounded bg-black text-white">
         <svg class="w-[24px] h-[24px] fill-[#ffffff]" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg">

  <path d="M224 0a128 128 0 1 1 0 256A128 128 0 1 1 224 0zM178.3 304h91.4c11.8 0 23.4 1.2 34.5 3.3c-2.1 18.5 7.4 35.6 21.8 44.8c-16.6 10.6-26.7 31.6-20 53.3c4 12.9 9.4 25.5 16.4 37.6s15.2 23.1 24.4 33c15.7 16.9 39.6 18.4 57.2 8.7v.9c0 9.2 2.7 18.5 7.9 26.3H29.7C13.3 512 0 498.7 0 482.3C0 383.8 79.8 304 178.3 304zM436 218.2c0-7 4.5-13.3 11.3-14.8c10.5-2.4 21.5-3.7 32.7-3.7s22.2 1.3 32.7 3.7c6.8 1.5 11.3 7.8 11.3 14.8v30.6c7.9 3.4 15.4 7.7 22.3 12.8l24.9-14.3c6.1-3.5 13.7-2.7 18.5 2.4c7.6 8.1 14.3 17.2 20.1 27.2s10.3 20.4 13.5 31c2.1 6.7-1.1 13.7-7.2 17.2l-25 14.4c.4 4 .7 8.1 .7 12.3s-.2 8.2-.7 12.3l25 14.4c6.1 3.5 9.2 10.5 7.2 17.2c-3.3 10.6-7.8 21-13.5 31s-12.5 19.1-20.1 27.2c-4.8 5.1-12.5 5.9-18.5 2.4l-24.9-14.3c-6.9 5.1-14.3 9.4-22.3 12.8l0 30.6c0 7-4.5 13.3-11.3 14.8c-10.5 2.4-21.5 3.7-32.7 3.7s-22.2-1.3-32.7-3.7c-6.8-1.5-11.3-7.8-11.3-14.8V454.8c-8-3.4-15.6-7.7-22.5-12.9l-24.7 14.3c-6.1 3.5-13.7 2.7-18.5-2.4c-7.6-8.1-14.3-17.2-20.1-27.2s-10.3-20.4-13.5-31c-2.1-6.7 1.1-13.7 7.2-17.2l24.8-14.3c-.4-4.1-.7-8.2-.7-12.4s.2-8.3 .7-12.4L343.8 325c-6.1-3.5-9.2-10.5-7.2-17.2c3.3-10.6 7.7-21 13.5-31s12.5-19.1 20.1-27.2c4.8-5.1 12.4-5.9 18.5-2.4l24.8 14.3c6.9-5.1 14.5-9.4 22.5-12.9V218.2zm92.1 133.5a48.1 48.1 0 1 0 -96.1 0 48.1 48.1 0 1 0 96.1 0z"></path>
</svg>
                                
</div>

{/*accès mon prifle*/}
<a href="/user" class="text-white mt-3 hover:text-customgold">Mon profile</a>   
  </li>
  <li class="flex">
      <div class="flex h-10 w-10  items-center justify-center rounded bg-black text-white">
            <svg class="w-[24px] h-[24px] fill-[#ffffff]" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg">
 
<path d="M224 0a128 128 0 1 1 0 256A128 128 0 1 1 224 0zM178.3 304h91.4c11.8 0 23.4 1.2 34.5 3.3c-2.1 18.5 7.4 35.6 21.8 44.8c-16.6 10.6-26.7 31.6-20 53.3c4 12.9 9.4 25.5 16.4 37.6s15.2 23.1 24.4 33c15.7 16.9 39.6 18.4 57.2 8.7v.9c0 9.2 2.7 18.5 7.9 26.3H29.7C13.3 512 0 498.7 0 482.3C0 383.8 79.8 304 178.3 304zM436 218.2c0-7 4.5-13.3 11.3-14.8c10.5-2.4 21.5-3.7 32.7-3.7s22.2 1.3 32.7 3.7c6.8 1.5 11.3 7.8 11.3 14.8v30.6c7.9 3.4 15.4 7.7 22.3 12.8l24.9-14.3c6.1-3.5 13.7-2.7 18.5 2.4c7.6 8.1 14.3 17.2 20.1 27.2s10.3 20.4 13.5 31c2.1 6.7-1.1 13.7-7.2 17.2l-25 14.4c.4 4 .7 8.1 .7 12.3s-.2 8.2-.7 12.3l25 14.4c6.1 3.5 9.2 10.5 7.2 17.2c-3.3 10.6-7.8 21-13.5 31s-12.5 19.1-20.1 27.2c-4.8 5.1-12.5 5.9-18.5 2.4l-24.9-14.3c-6.9 5.1-14.3 9.4-22.3 12.8l0 30.6c0 7-4.5 13.3-11.3 14.8c-10.5 2.4-21.5 3.7-32.7 3.7s-22.2-1.3-32.7-3.7c-6.8-1.5-11.3-7.8-11.3-14.8V454.8c-8-3.4-15.6-7.7-22.5-12.9l-24.7 14.3c-6.1 3.5-13.7 2.7-18.5-2.4c-7.6-8.1-14.3-17.2-20.1-27.2s-10.3-20.4-13.5-31c-2.1-6.7 1.1-13.7 7.2-17.2l24.8-14.3c-.4-4.1-.7-8.2-.7-12.4s.2-8.3 .7-12.4L343.8 325c-6.1-3.5-9.2-10.5-7.2-17.2c3.3-10.6 7.7-21 13.5-31s12.5-19.1 20.1-27.2c4.8-5.1 12.4-5.9 18.5-2.4l24.8 14.3c6.9-5.1 14.5-9.4 22.5-12.9V218.2zm92.1 133.5a48.1 48.1 0 1 0 -96.1 0 48.1 48.1 0 1 0 96.1 0z"></path>

</svg>
</div>
{/*accès mes publications*/}
  <a href="/myposts" class="text-white mt-3 hover:text-customgold">Mes publications</a>   
  </li>  

    <li class="flex">
        <div class="flex h-10 w-10  items-center justify-center rounded bg-black text-white">
            <svg class="w-[24px] h-[24px] fill-[#ffffff]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
<path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>

</svg>
</div>
{/*bouton déconnexion*/}
<button class=" bg-black-600  text-white rounded-lg hover:text-customgold" type="button" onClick={() => void auth.signoutSilent()}>
                Déconnexion
            </button>   
</li>                            
                            
  </ul>
</div>)}
 
      </ul>
    </div>
  </div>
</nav>


   </div>



        




 );
};

export default Nav;