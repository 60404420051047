import { useState, useEffect } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import '../index.css';

 
import axios from 'axios';

import AnnonceCard from './annonces/AnnonceCard'
 
const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1600 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 1600, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

export default function Annonces() {

    const [annonces, setAnnonces] = useState([]);

    useEffect(() => {
        axios
            .get('https://api.wisucine.com:8954/annonces')
            .then((response) => {
                setAnnonces(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [])
    
    return (
      <div class="text-center">
        <h2 class="mb-8 mt-8 text-3xl font-bold text-customgold">Opportunités</h2>
      <div class="bord-pelicule">
              
        <div class="text-center mx-auto md:px-6 bg-black">
             
              
                         
              <section class="bg-black">
<div class="pelicule">
 
  
                <Carousel
                  responsive={responsive}
                  autoPlay={true}
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  infinite={true}
                  partialVisible={false}
                  dotListClass="custom-dot-list-style"
                  
                  
                >
                { annonces.map(annonce => ( <AnnonceCard annonce={annonce} /> )) }
                </Carousel>
                </div>
                </section> 
                
                
                
        </div></div><a class= " text-customgold font-bold text-2xl " href="/annonces">Toutes nos opportunités</a></div>
    );
    
  };
  
