import React from 'react';

const Valeurs = () => {

 return (
   <div class="text-center ">
    <section class="w-100 border-[4px] border-customgold">
    <h1 class=" mb-6 text-3xl font-bold text-customgold">Nos valeurs</h1>

    <ul class="w-96 text-center dark:text-white mx-auto mb-6">
  <li
    class="w-full border-b-2 border-neutral-100 py-4 dark:border-white/10">
    <b class="text-customgold" >Égalité :</b> Tous les membres sont traités avec respect et ont un accès équitable aux
opportunités.
  </li>
  <li
    class="w-full border-b-2 border-neutral-100 py-4 dark:border-white/10">
    <b class="text-customgold" >Créativité :</b> Nous encourageons l&#39;innovation et la diversité artistique, offrant un espace où la
créativité est célébrée.
  </li>
  <li
    class="w-full border-b-2 border-neutral-100 py-4 dark:border-white/10">
    <b class="text-customgold" >Local :</b> Wisucine met en lumière les talents locaux et soutient les industries
cinématographiques locales.
  </li>
  <li
    class="w-full border-b-2 border-neutral-100 py-4 dark:border-white/10">
    <b class="text-customgold" >Jeunesse :</b> Nous investissons dans la prochaine génération de cinéastes en leur offrant des
opportunités et des ressources.
  </li>
  <li
    class="w-full border-b-2 border-neutral-100 py-4 dark:border-white/10">
    <b class="text-customgold" >Inclusion :</b> Notre communauté est inclusive, valorisant la diversité sous toutes ses formes et
assurant que chaque voix soit entendue et respectée.
  </li>
</ul>
</section>
   </div>
 );
};

export default Valeurs;