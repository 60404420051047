import React from 'react';

const MentionsLégales = () => {

 return (
   <div>
      <div class="container my-12 mx-auto px-2 md:px-4 border-[4px] border-customgold">

<section class="mb-32 text-center">

    <div class="flex justify-center ">
        <div class="text-center md:max-w-xl lg:max-w-3xl">
            <h2 class="mb-12 px-6 text-3xl text-customgold font-bold">
            Mentions légales - WISUCINE
            </h2>
        </div>
    </div>
<ul>
     
      
    <li class="font-bold">Raison sociale :</li>
    <li class="py-4 font-semibold">WISUCINE  – association<br/>
5 place messager,95400,Villiers-Le-Bel – Siège Social<br/>
RNA : W952014813</li>
    <li class="py-4 font-bold">N° TVA Intracommunautaire :</li> 
    <li class="py-1 font-semibold">FR64923640775</li>
    <li class="py-4 font-bold">Nous contacter par email :</li>
    <li class="py-1 font-semibold">contactequipe@filmdebanlieue.com</li>
    <li class="py-4 font-bold">Hébergeur :</li>
    <li class="py-1 font-semibold">Hostinger</li>
    <li class="font-semibold">N° de téléphone : 10 07</li>
     </ul>
</section>
</div>
   </div>
 );
};

export default MentionsLégales;