import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { TailSpin } from 'react-loader-spinner'

import WisucineAPI from '../../api/WisucineAPI';

import PostList from '../../components/posts/PostList';

const MyPostsPage = () => {

   const [posts, setPosts] = useState([]);
   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
        setIsLoading(true)
        WisucineAPI
            .get('posts')
            .then((response) => {
                console.log(response.data)
                setPosts(response.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => setIsLoading(false));
    }, [])

   if (isLoading) return <TailSpin visible={true} height="80" width="80" color="#d4a55c" radius="1" wrapperClass="my-20 flex-col items-center" />

   return (
    <>
        <PostList posts={posts} />
        
        <div className="mb-4 text-center">
            <Link to={'/posts'}>
                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">Créer une publication</button>
            </Link>
        </div>
        
    </>
   );

};

export default MyPostsPage;