import React, { useState } from 'react';
import { Link } from "react-router-dom";
import {
   Card,
   CardHeader,
   CardBody,
   Typography,
   Button,
   Avatar,
 } from "@material-tailwind/react";

import FooterCard from '../cards/FooterCard';

export default function FeedCard({ header, body }) {

    return (
      <Card className="w-full max-w-[48rem]" shadow={true} >
         
         <CardHeader color="transparent" floated={false} shadow={false} className="mx-0 flex items-center gap-4 p-2 rounded-none border-b">
            <Avatar size="lg" variant="circular" alt="tania andrew" src={header?.author?.avatar} />
            
            <div className="flex w-full flex-col gap-0.5">
               <div className="flex items-center justify-between">
                  <Typography variant="h5" color="blue-gray">{header?.author?.name}</Typography>
                  <Typography variant="h6" color="blue-gray">{header?.action}</Typography>
                  <div className="flex items-center gap-0">{header?.when}</div>
               </div>
               <Typography color="blue-gray">{header?.author?.job}</Typography>
            </div>
         </CardHeader>
         
         <CardBody>
            <img
            src={body.image}
            alt="card-image"
            className="h-full w-full object-cover"
            />
            <Typography variant="h6" color="gray" className="mb-4 uppercase">{body.categorie}</Typography>
            <Typography variant="h4" color="blue-gray" className="mb-2">{body.title}</Typography>
            <Typography color="gray" className="mb-8 font-normal">{body.content}</Typography>
            
            <Link to={body?.link?.link} class="inline-block">
         
               <Button variant="text" className="flex items-center gap-2">
                  {body?.link?.title}
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                  className="h-4 w-4"
                  >
                  <path
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                  />
                  </svg>
               </Button>
            </Link>
         </CardBody>
         
         <FooterCard />
      </Card>
    );

};

