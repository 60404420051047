import React from 'react';
import { useForm } from 'react-hook-form';
import {
    Card,
    CardBody,
    CardFooter,
    Button,
  } from "@material-tailwind/react";

const inputClass = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5";

export default function CreateCommentCard({ onclickSubmit }) {

    const { register, handleSubmit } = useForm({});

    const onSubmit = (data) => {
        onclickSubmit(data)
    };

    return (

        <Card>
            <form onSubmit={handleSubmit(onSubmit)}>
            <CardBody className='p-0'>
                <textarea placeholder="Ecrire un commentaire" class={inputClass} rows={5} {...register("content")}/>
            </CardBody>
            <CardFooter className='p-1'>
                <Button type='submit'>Envoyer</Button>
            </CardFooter>
            </form>
        </Card>

    );

}
