import React, { useState } from 'react';
import { Link } from "react-router-dom";
import {
   CardFooter,
   Button,
   Menu,
   MenuHandler,
   MenuList,
   MenuItem,
 } from "@material-tailwind/react";

import { AiFillLike } from "react-icons/ai";
import { AiOutlineLike } from "react-icons/ai";
import { MdOutlineInsertComment } from "react-icons/md";
import { MdOutlineShare } from "react-icons/md";
import { MdOutlineBookmarkBorder } from "react-icons/md";
import { MdOutlineBookmark } from "react-icons/md";

export default function FooterCard({ link }) {

   const [like, setLike] = useState(false);
   const [save, setSave] = useState(false);

    return (
      <CardFooter divider={true} className="p-0 flex justify-around">
         <Button onClick={() => setLike(!like)} className="flex justify-center gap-2" variant="text" >{like ? <AiFillLike /> : <AiOutlineLike />}J'aime</Button>
         <Link to={link}>
            <Button className="flex justify-center gap-2" variant="text"><MdOutlineInsertComment/>Commenter</Button>
         </Link>
         <Menu>
            <MenuHandler>
               <Button className="flex justify-center gap-2" variant="text"><MdOutlineShare/>Partager</Button>
            </MenuHandler>
            <MenuList>
               <MenuItem>Republier avec votre avis</MenuItem>
               <MenuItem>Republier</MenuItem>
               <MenuItem onClick={() => navigator.clipboard.writeText(link)}>Copier le lien</MenuItem>
            </MenuList>
         </Menu>
         <Button onClick={() => setSave(!save)} className="flex justify-center gap-2" variant="text">{save ? <MdOutlineBookmark /> : <MdOutlineBookmarkBorder />}Enregistrer</Button>
      </CardFooter>
    );

};

