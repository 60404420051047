import React from 'react'
import '../index.css';
import ADS from '../assets/image/ADS logo.jpg'
import Roissy from '../assets/image/roissy pays de france logo.jpg'
import Schoolab from '../assets/image/schoolab logo.jpg'
import Numix from '../assets/image/station numix logo.jpg'

export default function Trust() {
    return (
        <div class=" mx-auto md:px-6 ">
  
  <section class=" text-center mt-2 border-[2px] border-black">
    <h2 class=" text-3xl font-bold text-customgold mt-5">
     Ils nous font confiance
    </h2>
    <div class="grid gap-x-6 md:grid-cols-4 lg:gap-x-12 mt-8 text-white" >
      <div class="mb-12 md:mb-0">
        <div class="mb-6 inline-block rounded-md   p-4 text-primary">
           
        </div>
        <img class="mx-auto mb-5 rounded-lg shadow-lg dark:shadow-black/20 w-[150px] h-35" src={ADS} alt="ADS" />
         
      </div>

      <div class="mb-12 md:mb-0">
        <div class="mb-6 inline-block rounded-md   p-4 text-primary">
           
        </div>
        <img class="mx-auto mb-5 rounded-lg shadow-lg dark:shadow-black/20 w-[110px] h-[110px]" src={Roissy} alt="Roissy" />
         
      </div>

      <div class="mb-12 md:mb-0">
        <div class="mb-6 inline-block rounded-md  p-4 text-primary">
           
        </div>
        <img class="mx-auto mb-5 rounded-lg shadow-lg dark:shadow-black/20 w-[140px] h-[110px]" src={Schoolab} alt="Schoolab" />
         
      </div>

      <div class="mb-12 md:mb-0">
        <div class="mb-6 inline-block rounded-md  p-4 text-primary">
           
        </div>
        <img class="mx-auto mb-5 rounded-lg shadow-lg dark:shadow-black/20 w-[120px]" src={Numix} alt="Numix" />
         
      </div>
    </div>
  </section>
   
</div>
    )
  }
