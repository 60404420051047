import React from 'react';

const Contact = () => {

 return (
   <div class="border-[4px] border-customgold">
      <div class="container my-12 mx-auto px-2 md:px-4 ">

<section class="mb-32">

    <div class="flex justify-center">
        <div class="text-center md:max-w-xl lg:max-w-3xl">
            <h2 class="mb-12 px-6 text-3xl text-customgold font-bold">
                Nous contacter
            </h2>
        </div>
    </div>

    <div class="flex flex-wrap text-customgold">

        <form class="mb-12 w-full shrink-0 grow-0 basis-auto md:px-3 lg:mb-0 lg:w-5/12 lg:px-6 text-customgold">

            <div class="mb-3 w-full">
                <label class="block font-medium mb-[2px] text-customgold" htmlFor="exampleInput90">
                        Name
                </label>
                <input type="text" class="px-2 py-2 border w-full outline-none rounded-md" id="exampleInput90" placeholder="Name" />
            </div>

            <div class="mb-3 w-full">
                <label class="block font-medium mb-[2px] text-customgold" htmlFor="exampleInput90">
                        Email
                </label>
                <input type="email" class="px-2 py-2 border w-full outline-none rounded-md" id="exampleInput90"
                        placeholder="Enter your email address" />
            </div>

            <div class="mb-3 w-full">
                <label class="block font-medium mb-[2px] text-customgold" htmlFor="exampleInput90">
                        Message
                </label>
                <textarea class="px-2 py-2 border rounded-[5px] w-full outline-none" name="" id=""></textarea>
            </div>

            <button type="button"
                    class="mb-6 inline-block w-full rounded bg-customgold px-6 py-2.5 font-medium uppercase leading-normal text-white hover:shadow-md hover:bg-teal-500">
                    Envoyer
            </button>

        </form>

        <div class="w-full shrink-0 grow-0 basis-auto lg:w-7/12">
            <div class="flex flex-wrap">
                <div class="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:px-6">
                    <div class="flex items-start">
                        <div class="shrink-0">
                            <div class="inline-block rounded-md bg-teal-400-100 p-4 text-customgold">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="2" stroke="currentColor" class="h-6 w-6">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z" />
                                </svg>
                            </div>
                        </div>
                        <div class="ml-6 grow">
                            <p class="mb-2 font-bold">
                                Téléphone
                            </p>
                             
                            <p class="text-neutral-500 ">
                            06 58 53 17 06
                            </p>
                        </div>
                    </div>
                </div>
                <div class="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:px-6">
                    <div class="flex items-start">
                        <div class="shrink-0">
                            <div class="inline-block rounded-md bg-teal-400-100 p-4 text-customgold">
                            <svg class="w-6 h-6 fill-customgold" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

 
<path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"></path>

</svg>
                            </div>
                        </div>
                        <div class="ml-6 grow">
                        <p class="mb-2 font-bold">
                                Mail
                            </p>
                            <p class="text-neutral-500 ">
                            contactequipe@filmdebanlieue.com
                            </p>
                             
                        </div>
                    </div>
                </div>
                <div class="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:px-6">
                    <div class="align-start flex">
                        <div class="shrink-0">
                            <div class="inline-block rounded-md bg-teal-400-100 p-4 text-customgold">
                            <svg class="w-6 h-6 fill-customgold" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"></path>

</svg>
                            </div>
                        </div>
                        <div class="ml-6 grow">
                            <p class="mb-2 font-bold ">Adresse</p>
                            <p class="text-neutral-500 ">
                            5 place messager
                            </p>
                            <p class="text-neutral-500 ">
                            95400 Villiers Le Bel, FRANCE
                            </p>
                        </div>
                    </div>
                </div>
                <div class="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:px-6">
                    <div class="align-start flex">
                        <div class="shrink-0">
                            <div class="inline-block rounded-md bg-teal-400-100 p-4 text-customgold">
                                 
                            </div>
                        </div>
                         
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
</div>
   </div>
 );
};

export default Contact;