import '../index.css';

export default function ModOp() {
    return (


<div class=" my-24 mx-auto md:px-6">
 
  <div class=" text-center border-[2px] border-black w-full h-30">
    <h2 class="mb-8 mt-8 text-3xl font-bold text-customgold">
    Comment fonctionne Wisucine ?
    </h2>
    <div class="grid gap-x-6 md:grid-cols-4 lg:gap-x-12">
      <div class="mb-12 md:mb-0">
        <div class="mb-6 inline-block rounded-md bg-customgold p-4 text-white">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
</svg>

        </div>
        <h5 class="mb-4 text-lg font-bold">1. Inscription </h5>
        <p class="text-neutral-500 dark:text-neutral-300">
        Commencez par créer votre compte gratuitement et sans
engagement.
        </p>
      </div>

      <div class="mb-12 md:mb-0">
        <div class="mb-6 inline-block rounded-md bg-customgold p-4 text-white">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
</svg>

        </div>
        <h5 class="mb-4 text-lg font-bold">2. Profil </h5>
        <p class="text-neutral-500 dark:text-neutral-300">
        Ensuite, remplissez soigneusement les détails de votre profil.
        </p>
      </div>

      <div class="mb-12 md:mb-0">
        <div class="mb-6 inline-block rounded-md bg-customgold p-4 text-white">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
</svg>

        </div>
        <h5 class="mb-4 text-lg font-bold">3. Réseau </h5>
        <p class="text-neutral-500 dark:text-neutral-300">
        Établissez votre réseau professionnel spécialisé dans votre secteur
d&#39;activité.
        </p>
      </div>
      <div class="mb-12 md:mb-0">
        <div class="mb-6 inline-block rounded-md bg-customgold p-4 text-white">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
</svg>

        </div>
        <h5 class="mb-4 text-lg font-bold">4. Connection </h5>
        <p class="text-neutral-500 dark:text-neutral-300">
        Enfin, connectez-vous avec les annonces et les profils qui
correspondent à vos besoins et intérêts.
        </p>
      </div>
    </div>
  </div>
   
</div>




    )
  }
