import React from 'react';

const NoAuthorize = () => {

 return (
    <div class="my-40 text-center text-3xl font-bold text-customgold">
        <h1>Cette page est réservée aux membres, merci de vous connecter</h1>
    </div>
 );
};

export default NoAuthorize;