import React from 'react';

const CGU = () => {

 return (
   <div>
      <div class="container my-12 mx-auto px-2 md:px-4 border-[4px] border-customgold">

<section class="mb-32">

    <div class="flex justify-center">
        <div class="text-center md:max-w-xl lg:max-w-3xl">
            <h2 class="mb-12 px-6 text-2xl text-customgold font-bold">
            CONDITIONS GENERALES D’UTILISATION/ CGU
            </h2>
        </div>
    </div>
<ul>
    <li class="py-4 font-semibold"> Nous sommes l’association film de banlieue , association déclarée dont le siège social se situe au 5 place messager,95400,Villiers-Le-Bel immatriculée au Registre du commerce et
     des sociétés de Bobigny sous le n° 923640775 (ci-après « WISUCINE » ou « nous »).<br/>Tél : 06 58 53 17 06<br/>Email :  contactequipe@filmdebanlieue.com
     </li>
     <li class="py-4 font-semibold">
     Nous mettons à disposition la plateforme « Wisucine» sur le site https://wisucine.com/ internet wisucine.com , une plateforme d’annonce et de réseau dans le domaine du cinéma, de l’audiovisuel et du spectacle, permettant notamment de publier des annonces pour des missions en lien avec un projet audiovisuel ou cinématographique, de candidater aux annonces, et de se créer un réseau (ci-après la « Plateforme »).</li>
     <li class="py-4 font-semibold text-white">Les présentes conditions générales d’utilisation (ci-après les « CGU ») sont conclues entre film de banlieue  et toute personne souhaitant s’inscrire sur la Plateforme (ci-après l’« Utilisateur » ou « vous »).</li>
     <div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            1. Définitions
            </h3>
        </div>
     <li class="py-4 font-semibold">Les termes employés dans les CGU qui comportent une majuscule ont la signification indiquée ci-après. Lorsqu’ils sont employés sans majuscule, ils ont la signification utilisée dans le langage courant.</li>
    <li class="py-4 font-semibold">« Wisu basic » désigne un abonnement payant susceptible d’être souscrit par un Utilisateur sur la Plateforme permettant de bénéficier d’avantages dans le cadre de son utilisation.</li>
    <li class="py-4 font-semibold">« Annonce » désigne une page d’annonce éditée par un Utilisateur sur la Plateforme, rassemblant l’ensemble des détails et informations relatifs à une Mission.</li>
    <li class="py-4 font-semibold">« CGU » désigne les présentes conditions générales d’utilisation</li>
    <li class="py-4 font-semibold">« Compte Utilisateur » désigne le compte personnel d’un Utilisateur sur la Plateforme.</li>
    <li class="py-4 font-semibold">« Consommateur » désigne toute personne physique qui agit à des fins qui n'entrent pas dans le cadre de son activité commerciale, industrielle, artisanale, libérale ou agricole.</li>
    <li class="py-4 font-semibold">« Contenu » désigne toute information ou contenu mis en ligne par un Utilisateur sur la Plateforme, quelle que soit sa nature (notamment textes, écrits, données, images, visuels, logos, marques, photographies, sons, vidéos, projets, etc.).</li>
    <li class="py-4 font-semibold">« Données » désigne les données à caractère personnel ou à caractère non-personnel traitées par wisucine  pour votre compte dans le cadre de la fourniture de la Plateforme, telles que notamment les informations de votre Profil, de vos Annonces et, plus généralement, les Contenus que vous mettez en ligne.</li>
    <li class="py-4 font-semibold">« opportunités  » désigne toute mission relative à un projet audiovisuel et/ou cinématographique décrite dans une Annonce publiée par un Utilisateur pour laquelle d’autres Utilisateurs peuvent candidater en ligne</li>
    <li class="py-4 font-semibold">« Parties » nous désigne ensemble vous et nous.</li>
     
    <li class="py-4 font-semibold">« Politique de Confidentialité » désigne notre politique de confidentialité qui explique
aux personnes concernées par un traitement de données à caractère personnel que
nous réalisons en tant que responsable de traitement la façon dont nous traitons
leurs données et les droits dont ils disposent.</li>
    <li class="py-4 font-semibold">« Profil » désigne le profil d’un Utilisateur partagé sur la Plateforme comprenant
l’ensemble des informations le concernant (identité, coordonnées, compétences,
qualités, photos, etc.).</li>
    <li class="py-4 font-semibold">« RGPD » désigne le règlement européen 2016/679 du 27 avril 2016 relatif à la
protection des personnes physiques à l&#39;égard du traitement des données à caractère
personnel et à la libre circulation de ces données, et abrogeant la directive 95/46/CE
(règlement général sur la protection des données).</li>
    <li class="py-4 font-semibold">« Services » désigne l’ensemble des services techniques et humains assurés par
    wisucine dans le cadre de la mise à disposition de la Plateforme.</li>
    <li class="py-4 font-semibold">« Utilisateur » désigne toute personne inscrite sur la Plateforme et disposant à ce titre
    d’un Compte Utilisateur.</li>
    <div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            2. Objet
            </h3>
        </div>
    <li class="py-4 font-semibold">Les présentes CGU ont pour objet de régir les rapports juridiques entre WISUCINE et
    vous, lorsque vous êtes Utilisateur sur la Plateforme.</li>
    <li class="py-4 font-semibold">Elles déterminent vos droits et vos obligations lorsque vous utilisez la Plateforme, et
    constituent à ce titre l’unique contrat entre WISUCINE et vous.</li>
    <li class="py-4 font-semibold">Vous devez accepter nos CGU pour utiliser la Plateforme. Lorsque vous les accepter,
vous vous engagez à les respecter aussi longtemps que vous bénéficierez des
Services.</li>
 <div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            3. Durée
            </h3>
        </div>
    <li class="py-4 font-semibold">Les CGU entrent en vigueur à la date où vous les acceptez en ligne (case à cocher).</li>
    <li class="py-4 font-semibold">Elles resteront applicables entre nous tant que vous disposerez d&#39;un Compte
Utilisateur sur la Plateforme.
Les CGU peuvent être résiliées dans les conditions prévues à l’article 18.</li>
<div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            4. Nos services
            </h3>
        </div>
        <div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            4.1 Description générale
            </h3>
        </div>
    <li class="py-4 font-semibold">Nos Services consistent à vous fournir un droit d’accès et d’utilisation de notre
Plateforme, à la maintenir disponible dans la mesure du possible et à mettre à votre
disposition notre service client.</li>
    <li class="py-4 font-semibold">Notre plateforme est un espace d&#39;annonces et de réseau spécialisé dans le
cinéma, l&#39;audiovisuel et le spectacle, conçu pour vous offrir un environnement
égalitaire et affinitaire. Vous pouvez y publier des annonces de missions, postuler à celles qui vous intéressent, et créer un réseau basé sur vos affinités. De plus, la
plateforme vous permet de partager vos projets et de vous connecter comme sur
un réseau social, favorisant ainsi la création de liens entre les membres.</li>
    <li class="py-4 font-semibold">Aussi nous ne sommes en aucun cas partie aux relations contractuelles entre
Utilisateurs afférentes aux Missions ou, plus généralement, à toute autre relation
initiée entre des Utilisateurs qui se sont rencontrés par l’intermédiaire de la
Plateforme.</li>
    <li class="py-4 font-semibold">Notre rôle se limite, en tant que prestataire technique, à mettre à disposition la
    Plateforme et l’audience de notre site internet.</li>
    <li class="py-4 font-semibold">Nous ne sommes en aucun cas revendeur ou commissionnaire des prestations de
    service susceptibles d’être fournies par les Utilisateurs.</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            4.2 Fonctionnalités de la Plateforme
            </h3>
        </div></li>
    <li class="font-semibold">Notre Plateforme comprend les fonctionnalités suivantes :</li>
    <li class="font-semibold">- fonction « Tableau de bord » ;</li>
    <li class="font-semibold">- fonction « opportunité » ;</li>
    <li class="font-semibold">- fonction « Profils » ;</li>
    <li class="font-semibold">- fonction « Réseau » ;</li>
    <li class="font-semibold">- fonction «abonnement» ;</li>
    <li class="font-semibold">- fonction « fils d’actualité »</li>
    <li class="py-4 font-semibold">Les fonctionnalités ci-dessus sont décrites plus précisément à l’article 8 des présentes.</li>
    <li class="py-4 font-semibold">Nous pouvons faire évoluer les fonctionnalités de la Plateforme à tout moment. Nous
veillerons à ce que ces évolutions se fassent sans régression du niveau de service ni
perte de données pour vous.</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            4.3 Traitement différencié des Utilisateurs disposant d’un Abonnement
Premium
            </h3>
        </div></li>
    <li class="py-4 font-semibold">Nous appliquons un traitement différencié entre les Utilisateurs selon qu’ils ont
    souscrit un Wisu basic ou non.</li>
    <li class="py-4 font-semibold">Tous les Utilisateurs, abonnés ou non, ont accès aux mêmes fonctionnalités
    principales.</li>
    <li class="py-4 font-semibold">Toutefois les Utilisateurs ayant souscrit à Wisu basic bénéficient, selon la formule
    choisie, d’avantages supplémentaires par rapport aux autres Utilisateurs.</li>
    <li class="py-4 font-semibold">La liste des avantages supplémentaires de chaque formule est accessible sur notre
    site internet à l’adresse suivante https://wisucine.com/ .</li>
    <div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            5. Avantage procuré à WISUCINE en contrepartie des Services
            </h3>
        </div>
    <li class="py-4 font-semibold">Sauf souscription à l’un des abonnement, l’accès et l’utilisation de la Plateforme est
    gratuit.</li>
    <li class="py-4 font-semibold">Nous retirons cependant un avantage de votre inscription sur la Plateforme en
collectant des données personnelles vous concernant à des fins de prospection
commerciale.</li>
    <li class="py-4 font-semibold">Nous vous invitons à consulter notre Politique de confidentialité accessible sur la

Plateforme dans laquelle nous vous expliquons en détail les traitements que nous
réalisons.</li>
    <li class="py-4 font-semibold">En cas de souscription à l’abonnement Wisu basic , cet avantage nous est procuré en
    complément du prix de l’abonnement.</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            6. Votre inscription à la Plateforme
            </h3>
        </div></li>
        <li class="font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            6.1 Conditions d’inscription
            </h3>
        </div></li>
    <li class="font-semibold">Pour vous inscrire sur notre Plateforme et accéder à ses fonctionnalités, vous devez
    suivre les étapes de création d’un Compte Utilisateur sur la Plateforme :</li>
    <li class="font-semibold">- Etape 1 : vous devez accéder au formulaire d’inscription :</li>
    <li class="font-semibold">- Etape 2 : vous devez renseigner toutes les informations requises du formulaire
    d’inscription (type de Profil, nom, prénom, adresse e-mail) et créer un mot de passe ;</li>
    <li class="font-semibold">- Etape 3 : vous devez lire les présentes CGU ;</li>
    <li class="font-semibold">- Etape 4 : vous devez accepter formellement les présentes CGU en cochant la case
    d’acceptation en ligne ;</li>
    <li class=" font-semibold">- Etape 5 : vous devez valider vos inscriptions en cliquant sur le bouton « se connecter/
    s’inscrir » prévu à cet effet.</li>
    <li class="py-4 font-semibold">Après avoir validé votre inscription, vous recevrez un mail de confirmation de votre
inscription et de la création de votre Compte Utilisateur, comprenant la version des
présentes CGU que vous avez acceptée. Vous pourrez alors utiliser les fonctionnalités
de la Plateforme.</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            7. Votre accès à la Plateforme
            </h3>
        </div></li>
        <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            7.1 Droit d’accès et d’utilisation de la Plateforme
            </h3>
        </div></li>
    <li class="py-4 font-semibold">En tant qu’Utilisateur régulièrement inscrit, vous disposez d’un droit d’accès et
    d’utilisation à distance de la Plateforme via votre Compte Utilisateur.</li>
    <li class="py-4 font-semibold">Nous vous concédons ce droit à titre personnel, non exclusif, non cessible, pour le
monde entier, pour la durée de votre inscription sur la Plateforme, et uniquement aux
fins d’utilisation de celle-ci dans les conditions et limites prévues dans les présentes,
et en toute hypothèse conformément à sa destination.</li>
    <li class="py-4 font-semibold">L’accès à notre Plateforme s’effectue à travers le réseau Internet, via notre
    site https://wisucine.com/</li>
    <li class="py-4 font-semibold">Le matériel et la connexion à internet nécessaires pour accéder à notre Plateforme

restent à vos frais et ne vous sont pas fournis. Vous avez la charge exclusive du
fonctionnement de votre matériel, de votre connexion, et plus généralement de
l’environnement technique avec lequel vous utilisez la Plateforme.</li>
<li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            7.2 Votre Compte Utilisateur et vos identifiants
            </h3>
        </div></li>
    <li class="py-4 font-semibold">Nous mettons à votre disposition un Compte Utilisateur vous permettant de
    bénéficier d’un accès personnalisé à la Plateforme et d’utiliser ses fonctionnalités.</li>
    <li class="py-4 font-semibold">Vous pouvez accéder à votre Compte Utilisateur grâce à vos identifiants (adresse mail
et mot de passe renseignés lors de l’inscription). Ces identifiants sont confidentiels et
personnels ; ils ne doivent en aucun cas être partagés avec des tiers.</li>
    <li class="py-4 font-semibold">En cas de perte, de suspicion de divulgation ou de divulgation avérée à des tiers, vous
devez immédiatement générer un renouvellement de votre mot de passe (fonction «
mot de passe oublié ») et nous signaler l’incident.</li>
    <li class="py-4 font-semibold">Les actions réalisées sur la Plateforme une fois connecté avec les identifiants sont
    réputées avoir été accomplies par vous.</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            7.3 Compatibilité, interopérabilité et restriction de logiciel
            </h3>
        </div></li>
        <li class="py-4 font-semibold">Notre Plateforme fonctionne sur le réseau Internet et est à ce titre compatible avec :</li>
    <li class="font-semibold">- tout type de matériel permettant d’accéder à Internet : ordinateur (pc, mac),tablettes, smartphones, etc. ;</li>
    <li class="font-semibold">- tout système d’exploitation : Android, Ios, Windows, macOS, Linux.</li>
    <li class="font-semibold">- tout navigateur web : Microsoft Edge, Google Chrome, Mozilla Firefox, Safari, etc.</li>
    <li class="py-4 font-semibold">Notre Plateforme n’est pas interopérable avec d’autres matériels ou logiciels.
    Il n’existe aucune restriction de logiciel pour utiliser la Plateforme.</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            7.4 Disponibilité de la Plateforme
            </h3>
        </div></li>
    <li class="py-4 font-semibold">Nous faisons nos meilleurs efforts pour que la disponibilité de la Plateforme se
rapproche le plus possible d’une disponibilité 24 heures sur 24 et 7 jours sur 7. Nous
nous engageons uniquement sur une obligation de moyens.</li>
    <li class="py-4 font-semibold">Vous reconnaissez et acceptez que la Plateforme pourra connaître des périodes
    d’indisponibilité temporaire à toute heure de la journée.</li>
    <li class="font-semibold">L’accès à Plateforme pourra être momentanément interrompu notamment :</li>
    <li class="font-semibold">- pour toutes causes indépendantes de notre volonté, notamment en cas de
    saturation au niveau français, ou mondial du réseau Internet ;</li>
    <li class="font-semibold">- pour des raisons de sécurité, notamment si nous constatons une utilisation suspecte
    de votre Compte Utilisateur ;</li>
    <li class="font-semibold">- pour toute opération de maintenance ou de mise à jour de notre Plateforme.</li>
    <li class="py-4 font-semibold">Nous veillons à vous informer au préalable de toute interruption programmée due à
nos opérations de maintenance ou mise à jour. Dans la mesure du possible, nous
veillons à réaliser ces opérations du lundi au vendredi, avant neuf (9) heures et après
dix-huit (18) heures ou le samedi et dimanche toute la journée. Toutefois, certaines
interventions d’urgence pourront nous obliger à intervenir à tout moment de la
journée sans pouvoir vous informer préalablement.</li>
<li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            8. Fonctionnement de la Plateforme
            </h3>
        </div></li>
        <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            8.1 Les Profils
            </h3>
        </div></li>
    <li class="py-4 font-semibold">▪ Types de Profil
Lors de votre inscription, vous avez la possibilité de choisir votre Profil parmi les types
de Profil proposés sur la Plateforme.</li>
    <li class="py-4 font-semibold">Chaque type de Profil donne accès aux mêmes fonctionnalités principales.</li>
    <li class="py-4 font-semibold">En revanche, les informations du Profil à compléter peuvent être différentes en
    fonction de leur pertinence par rapport au type de Profil choisi.</li>
    <li class="py-4 font-semibold">▪ Votre Profil
    Vous pouvez éditer votre Profil à partir de la rubrique « profil» une fois connecté</li>
    <li class="py-4 font-semibold">En cliquant sur le bouton « modifier mon profil », vous accédez à un formulaire vous
permettant de renseigner les informations de votre Profil (identité, coordonnées, site
internet, réseaux sociaux, disponibilité, expérience, métiers, compétences, qualités
humaines, etc.).</li>
    <li class="py-4 font-semibold">Le formulaire varie en fonction du type de Profil choisi lors de l’inscription.</li>
    <li class="py-4 font-semibold">Vous avez la possibilité d’ajouter une ou plusieurs photos ou vidéos à votre Profil.</li>
    <li class="py-4 font-semibold">Lorsque vous validez votre Profil, celui-ci est publié sur la Plateforme et devient
    accessible à tous les Utilisateurs.</li>
    <li class="py-4 font-semibold">Vous reconnaissez être le seul responsable des Contenus (informations personnelles,
        photos, vidéos) ajoutés à votre Profil.</li>
    <li class="py-4 font-semibold">Les informations figurant sur votre Profil doivent être exactes, sincères et complètes,
et ce, à tout moment. Aussi vous devez mettre à jour votre Profil en cas de
modification de ces informations.</li>
    <li class="py-4 font-semibold">Vous pouvez modifier votre Profil à tout moment à partir de votre tableau de bord.</li>
    <li class="py-4 font-semibold">▪ Profils des autres Utilisateurs
Vous pouvez visualiser les Profils des autres Utilisateurs et vous connecter avec eux en
vous rendant dans la rubrique « fil d’axtualité».</li>
    <li class="font-semibold">Cette rubrique vous permet :</li>
    <li class="font-semibold">- d’accéder à la liste des Profils présents sur la Plateforme ;</li>
    <li class="font-semibold">- de rechercher les Profils grâce à des filtres de recherche ;</li>
    <li class="font-semibold">- de faire des demande de connexion à d’autres Utilisateurs en cliquant sur le bouton
    « se connecter ».</li>
    <li class="py-4 font-semibold">Lorsque votre demande de connexion est acceptée par l’Utilisateur, cet Utilisateur
intègre votre réseau.
Vous pouvez visualiser votre réseau dans la rubrique « réseau » ou à partir de votre
tableau de bord.</li>
    <li class="py-4 font-semibold">▪ Référencement et classement des Profils
Les Profils sont référencés sur la Plateforme dans les catégories déterminées par
WISUCINE , en fonction des informations renseignées dans le formulaire d’édition du
Profil.</li>
    <li class="py-4 font-semibold">Les autres Utilisateurs pourront rechercher les Profils par le biais de mots clés sur un

moteur de recherche ou via des filtres de recherche. Les résultats de recherche
apparaitront conformément aux modalités de classement que nous déterminons.</li>
    <li class="py-4 font-semibold">Nous vous invitons à consulter notre rubrique « Référencement et classement des
contenus » pour connaître le détail des conditions de référencement et des modalités
de classement des Profils.</li>
<li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            8.2 Les Annonces
            </h3>
        </div></li>
    <li class="py-4 font-semibold">▪ Publication de vos Annonces
Vous pouvez publier des Annonces à partir de la rubrique « Opportunité » ou à partir
de votre tableau de bord.</li>
    <li class="py-4 font-semibold">En cliquant sur le bouton « publier une annonce », vous accédez à un formulaire vous
permettant de renseigner les informations qui figureront dans votre Annonce (type
de contrat proposé, secteur, métiers, titre, description, localisation, dates de début et
de fin de la Mission) et de gérer les modalités de publication.</li>
    <li class="py-4 font-semibold">Lorsque vous validez votre Annonce, celle-ci est publiée sur la Plateforme et
    accessible à tous les Utilisateurs.</li>
    <li class="py-4 font-semibold">Vous reconnaissez être le seul responsable des Contenus publiés dans vos Annonces.</li>
    <li class="py-4 font-semibold">Les informations figurant dans vos Annonces doivent être exactes, sincères et
complètes, et ce, à tout moment. Aussi vous devez mettre à jour vos Annonces en cas
de modification de ces informations.</li>
    <li class="py-4 font-semibold">Vous pouvez modifier ou supprimer vos Annonces à tout moment.</li>
    <li class="py-4 font-semibold">La Plateforme n’a pas vocation à permettre la publication d’Annonces de prestation
de service. Aussi toute Annonce publiée par un Utilisateur en vue de la fourniture, par
lui-même ou par un tiers, d’une prestation de service sera déréférencée de la
Plateforme.</li>
    <li class="py-4 font-semibold">▪ Annonces des autres Utilisateurs – Candidatures
La rubrique « opportunité » vous permet également de visualiser les Annonces en
cours publiées par d’autres Utilisateurs et de rechercher les Annonces pertinentes
grâce à un moteur et des filtres de recherche.</li>
    <li class="py-4 font-semibold">En vous rendant sur l’Annonce d’un autre Utilisateur, vous avez la possibilité d’envoyer
    votre candidature en cliquant sur le bouton prévu à cet effet.</li>
    <li class="py-4 font-semibold">▪ Référencement et classement des Annonces
Les Annonces sont référencées sur la Plateforme dans les catégories déterminées par
WISUCINE en fonction des informations renseignées dans le formulaire de création
de l’Annonce.</li>
    <li class="py-4 font-semibold">Les autres Utilisateurs pourront rechercher les Annonces référencées par le biais de
mots clés sur un moteur de ou via des filtres de recherche. Les résultats de recherche
apparaitront conformément aux modalités de classement que nous déterminons.</li>
    <li class="py-4 font-semibold">Nous vous invitons à consulter notre rubrique « Modalités de référencement et de
classement des contenus » pour connaître le détail des conditions de référencement
et des modalités de classement des Annonces.</li>
<li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            8.3 Votre Tableau de bord
            </h3>
        </div></li>
    <li class="py-4 font-semibold">La rubrique « Tableau de bord » vous permet de disposer d’une vue d’ensemble de
    votre activité sur la Plateforme.</li>
    <li class="py-4 font-semibold">Il vous permet de visualiser sur une seule et même page les dernières Annonces
publiées sur la Plateforme, les Profils de votre réseau, les dernières actualités en lien
avec le monde du cinéma et de l’audiovisuel.</li>
    <li class="py-4 font-semibold">A partir du tableau de bord vous pouvez également publier une Annonce, modifier les
    informations de votre Compte Utilisateur et éditer votre Profil.</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            8.4 Votre réseau
            </h3>
        </div></li>
    <li class="py-4 font-semibold">La rubrique « Réseau » vous permet de visualiser et filtrer les Profils des Utilisateurs
    avec lesquels vous êtes connectés.</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            8.5 Les actualités
            </h3>
        </div></li>
    <li class="py-4 font-semibold">La rubrique « Actualités » vous permet d’accéder à des articles de presse en lien avec
    le secteur du cinéma et de l’audiovisuel.</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            9. Contenus
            </h3>
        </div></li>
        <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            9.1 Notre rôle d’hébergeur
            </h3>
        </div></li>
    <li class="py-4 font-semibold">Nous n’exerçons ni contrôle préalable ni surveillance générale des Contenus que vous
    diffusez sur la Plateforme.</li>
    <li class="py-4 font-semibold">Notre rôle est celui d’un hébergeur au sens de la directive européenne 2000/31/CE du
    8 juin 2000.</li>
    <li class="py-4 font-semibold">Par conséquent, nous ne saurions être tenu responsable en cas de publication de
Contenus illicites par un Utilisateur, sauf à ce que nous n’agissions pas promptement
pour retirer ou rendre inaccessible un Contenu illicite dont nous aurions eu
connaissance ou qu’un Utilisateur nous aurait signaler.</li>
<li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            9.2 Signalement de Contenus illicites
            </h3>
        </div></li>
    <li class="py-4 font-semibold">Il est possible de nous notifier des contenus manifestement illicites en remplissant le
formulaire électronique prévu à cet effet accessible via la Plateforme ou transmis par
email à la suite d’un signalement.</li>
    <li class="py-4 font-semibold">Conformément au Règlement (UE) 2022/2065 du 19 octobre 2022 concernant un
marché unique des services numériques (DSA), la notification devra mentionner
l’ensemble des informations suivantes :</li>
    <li class="font-semibold">- une explication suffisamment étayée des raisons pour lesquelles le notifiant allègue
    que les informations en question sont du contenu illicite ;</li>
    <li class="font-semibold">- une indication claire de l’emplacement électronique exact de ces informations,
comme l’URL ou les URL exact(s), et, le cas échéant, des informations
complémentaires permettant d’identifier le contenu illicite ;</li>
    <li class="font-semibold">- le nom et l’adresse email du notifiant ;</li>
    <li class="font-semibold">- une déclaration confirmant que le notifiant pense, de bonne foi, que les informations
et les allégations contenues dans la notification sont exactes et complètes.
En cas de publication de Contenus illicites par un Utilisateur, nous nous réservons la
possibilité de prendre des mesures telles que :</li>
    <li class="font-semibold">- le retrait ou le déclassement du Contenu concerné ;</li>
    <li class="font-semibold">- la suspension temporaire du Compte Utilisateur de l’auteur du Contenu ;</li>
    <li class="font-semibold">- la fermeture définitive du Compte Utilisateur de l’auteur du Contenu (notamment
        en cas de publication répétée de Contenus illicites).</li>
    <li class="py-4 font-semibold">Lors que nous prenons de telles mesures, nous en informons l’Utilisateur et lui
communiquons, au plus tard au jour où la mesure est effective, un exposé des motifs
lui expliquant les raisons et les conséquences de la mesure prise.</li>
<li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            9.3 Vos Contenus
            </h3>
        </div></li>
    <li class="py-4 font-semibold">Vous êtes et restez propriétaire des Contenus que vous publiez sur la Plateforme, sous
    réserve des droits des tiers sur ces Contenus.</li>
    <li class="font-semibold">Vous nous autorisez à exploiter les Contenus que vous publiez pour notre propre
    compte pour les finalités suivantes :</li>
    <li class="font-semibold">- pour fournir les Services et notamment pour la mise à disposition de la Plateforme,
    pour la durée du Contrat ;</li>
    <li class="font-semibold">- pour développer, améliorer, alimenter, enrichir notre algorithm, sans limitation de
    durée ;</li>
    <li class="font-semibold">- pour les utiliser à toutes fins dans un cadre strictement interne à WISUCINE sans
    limitation de durée.</li>
    <li class="font-semibold">Lorsque vos Contenus sont des oeuvres couvertes par le droit d’auteur :</li>
    <li class="font-semibold">- vous devez vous assurez avant toute publication de disposer des droits ou
    autorisations nécessaires pour ce faire ;</li>
    <li class="font-semibold">- vous nous consentez sur ces Contenus une licence non-exclusive, à titre gratuit, pour
le monde entier, comprenant les droits de reproduction et de représentation aux fins
et pour les durées indiquées ci-dessus.</li>
    <li class="py-4 font-semibold">Vous nous autorisez en outre gracieusement à mentionner et reproduire votre nom
commercial, ainsi que vos marques et logos éventuels au titre des références
commerciales sur la Plateforme et sur nos supports promotionnels et commerciaux
en ligne ou hors ligne, sans limitation de durée.</li>
<li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            10. Vos obligations
            </h3>
        </div></li>
        <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 text-2xl text-customgold font-bold">
            10.1 Relations avec les autres Utilisateurs dans le cadre des Missions
            </h3>
        </div></li>
    <li class="py-4 font-semibold">Nous attachons une importance prépondérante au bon déroulement des Missions et
des relations initiées par l’intermédiaire de notre Plateforme et à ce que l’utilisation de
la Plateforme constitue une expérience satisfaisante pour tous nos Utilisateurs.</li>
    <li class="font-semibold">Conscient de ces exigences déterminantes, vous vous engagez :</li>
    <li class="font-semibold">- à respecter toute réglementation qui vous est applicable lorsque vous êtes amené à
recruter des Utilisateurs pour des Missions (droit du travail, réglementations relatives
au travail des mineurs notamment) ;</li>
    <li class="font-semibold">- à respecter vos engagements contractuels découlant de toute relation initiée avec
    un Utilisateur rencontré via la Plateforme ;</li>
    <li class="font-semibold">- lorsque vous réalisez une Mission, à l’exécuter avec un niveau de qualité
    professionnel et conformément aux usages commerciaux applicables à votre activité ;</li>
    <li class="font-semibold">- à adopter une communication adaptée et respectueuse dans vos échanges avec les
autres Utilisateurs et à faire preuve en tout temps de bonne foi dans votre relation
avec ces derniers ;</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 text-2xl text-customgold font-bold">
            10.2 Utilisation de la Plateforme – Comportements interdits
            </h3>
        </div></li>
    <li class="font-semibold">Vous devez utiliser la Plateforme conformément à sa destination, dans le respect de la
loi et des présentes CGU et de toutes règles d’utilisation que nous communiquons ou

rendons accessible, notamment par voie d’affichage, de publication, ou de notification
sur la Plateforme.</li>
    <li class="font-semibold">A ce titre, vous ne devez notamment pas :</li>
    <li class="font-semibold">- copier, reproduire, modifier, créer des oeuvres dérivées, distribuer ou divulguer au
public tout contenu de la Plateforme sans nous demander préalablement
l’autorisation ;</li>
    <li class="font-semibold">- donner accès à votre Compte Utilisateur à une autre personne ou divulguer vos
    identifiants personnels ;</li>
    <li class="font-semibold">- utiliser la Plateforme avec un compte qui n’est pas le vôtre ;</li>
    <li class="font-semibold">- perturber ou tenter de perturber le bon fonctionnement de la Plateforme ou des
    activités qui s&#39;y déroulent ;</li>
    <li class="font-semibold">- distribuer sur la Plateforme des virus ou d&#39;autres technologies susceptibles de nuire
    au fonctionnement de la Plateforme ou de nuire aux tiers ;</li>
    <li class="font-semibold">- contourner le fonctionnement de la Plateforme, d’une quelconque façon que ce soit,
et notamment contourner les protocoles d&#39;exclusion des robots ou toute autre
mesure utilisée pour empêcher ou limiter l&#39;accès à la Plateforme ;</li>
    <li class="font-semibold">- utiliser des automates (bots), des araignées (spider), des scraper ou tout autre
procédé automatisé pour accéder à la Plateforme à quelque fin que ce soit, sans nous
demander préalablement l’autorisation ;</li>
    <li class="font-semibold">- faire de la fouille de données sur la Plateforme sans nous demander préalablement
    l’autorisation ;</li>
    <li class="font-semibold">- extraire et/ou réutiliser une partie qualitativement ou quantitativement substantielle
de notre base de données ou extraire et/ou réutiliser de manière répétée et
systématique tout ou partie de celle-ci.</li>
<li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            10.3 Données personnelles des Utilisateurs
            </h3>
        </div></li>
    <li class="py-4 font-semibold">Il est expressément interdit d’extraire les données personnelles des autres Utilisateurs
qui sont mises en ligne sur la Plateforme et de réaliser un quelconque traitement de
ces données.</li>
    <li class="font-semibold">Il est en outre expressément interdit d’utiliser les données personnelles des autres
Utilisateurs (données des Profils principalement) à d’autres fins que celles résultant de
la destination de la Plateforme, à savoir :</li>
    <li class="font-semibold">- trouver des Missions ;</li>
    <li class="font-semibold">- recruter des personnes qualifiées pour vos projets audiovisuels/cinématographiques
    ;</li>
    <li class="font-semibold">- vous créer un réseau ;</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            10.4 Publication des Contenus
            </h3>
        </div></li>
    <li class="font-semibold">La Plateforme vous permet de créer et publier des Contenus notamment dans votre
    Profil et dans vos Annonces. Vous ne devez pas :</li>
    <li class="font-semibold">- publier des Contenus illicites ou contrevenants aux stipulations des présentes ;</li>
    <li class="font-semibold">- publier des Contenus injurieux, diffamatoires, violents, discriminants (contenus
        racistes, homophobes, transphobes, antisémites, etc.), ou plus généralement illicites ;</li>
    <li class="font-semibold">- publier des Contenus inappropriés ou contraires aux bonnes moeurs ;</li>
    <li class="font-semibold">- publier des Contenus dont la reproduction et/ou la diffusion est susceptible de
constituer une violation d’un droit de tiers, tel que par exemple un droit de propriété
intellectuelle ou le droit à l’image.</li>
<li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            10.5 Manquement à vos obligations
            </h3>
        </div></li>
    <li class="py-4 font-semibold">▪ Suspension, restriction ou résiliation</li>
    <li class="py-4 font-semibold">Tout manquement de votre part à l’une quelconque des obligations précitées pourra
donner lieu à la suspension ou à des restrictions de votre accès à la Plateforme, voire à
la résiliation définitive de votre Compte Utilisateur, conformément aux articles 17 et 18
des présentes.</li>
    <li class="py-4 font-semibold">▪ Votre responsabilité
Vous êtes seul responsable des dommages causés par vos manquements aux
obligations précitées, que ce soit envers WISUCINE, un Utilisateur ou un tiers.</li>
    <li class="py-4 font-semibold">Vous ferez donc votre affaire, sans l&#39;intervention de WISUCINE, de tous éventuels
    litiges avec un autre Utilisateur ou un tiers résultant de tels manquements.</li>
    <li class="font-semibold">En particulier, vous êtes responsable entier et exclusif :</li>
    <li class="font-semibold">- de l’exécution des contrats conclus avec les Utilisateurs rencontrés par
    l’intermédiaire de la Plateforme.</li>
    <li class="font-semibold">- de tous les Contenus que vous mettez en ligne et de tout défaut, erreur ou omission
    quant aux Contenus que vous publiez sur la Plateforme ;</li>
    <li class="py-4 font-semibold">- de l’utilisation que vous faites de la Plateforme.</li>
    <li class="font-semibold">▪ Votre engagement de garantie</li>
    <li class="font-semibold">Vous garantissez WISUCINE contre toute action, amiable ou judiciaire, émanant d’un
Utilisateur ou d’un tiers engagée à son encontre du fait d’un manquement de votre
part aux stipulations des présentes.</li>
    <li class="font-semibold">Vous vous engagez à ce titre à indemniser intégralement WISUCINE le cas échant de
tous frais qu’elle aurait engagés pour sa défense et pour l’indemnisation de tout
Utilisateur ou tiers au titre d’un accord amiable, d’une transaction, ou d’une décision
de justice.</li>
<li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            11. Propriété intellectuelle deWISUCINE
            </h3>
        </div></li>
    <li class="py-4 font-semibold">Nous sommes titulaires de l’ensemble des droits de propriété intellectuelle portant
sur notre Plateforme et ses composants (logiciels, textes, images, visuels, marques,
noms de domaine, etc.) ainsi que sur les documents que nous vous remettons,
l’acceptation des CGU et l’utilisation de la Plateforme n’opérant aucun transfert de
droits de propriété intellectuelle à votre profit.</li>
    <li class=" py-4 font-semibold">Nous vous concédons uniquement un droit d’accès et d’utilisation de la Plateforme et
des fonctionnalités qu’elle contient. Ce droit d’accès et d’utilisation est consenti à titre
personnel, non exclusif, non cessible, pour le monde entier, pour la seule durée des
présentes.</li>
    <li class="py-4 font-semibold">Vous n’avez pas droit d’utiliser les marques et signes distinctifs désignant WISUCINE,
la Plateforme ou les fonctionnalités qu’elle contient, sans autorisation préalable,
expresse et écrite de notre part.</li>
<li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            12. Garanties de WISUCINE
            </h3>
        </div></li>
    <li class="py-4 font-semibold">Vous pouvez bénéficier de la garantie légale de conformité décrite dans l’encadré ci-
dessous uniquement si vous êtes un Consommateur ou un « non-professionnel » au
sens du Code de la consommation.</li>
    <li class="py-4 font-semibold">Tout Utilisateur a droit à la mise en oeuvre de la garantie légale de conformité en cas
d&#39;apparition d&#39;un défaut de conformité pendant toute la durée de son inscription à la
Plateforme ou pendant un délai de 2 ans à compter de la fourniture de la Plateforme
en cas de durée d’inscription inférieure à 2 ans.</li>
    <li class="py-4 font-semibold">Durant ce délai, l’Utilisateur n&#39;est tenu d&#39;établir que l&#39;existence du défaut de
    conformité et non la date d&#39;apparition de celui-ci.</li>
    <li class="py-4 font-semibold">La garantie légale de conformité emporte obligation de fournir toutes les mises à jour
nécessaires au maintien de la conformité de la Plateforme durant toute la durée de sa
fourniture à l’Utilisateur.</li>
    <li class="py-4 font-semibold">La garantie légale de conformité donne à l’Utilisateur droit à la mise en conformité de
la Plateforme sans retard injustifié suivant sa demande, sans frais et sans
inconvénient majeur pour lui.</li>
    <li class="font-semibold">Tout Utilisateur peut obtenir une réduction du prix le cas échéant en conservant son
accès à la Plateforme, ou il peut mettre fin au contrat en se faisant rembourser
intégralement contre renoncement à son accès à la Plateforme, ou il peut obtenir la
restitution de tout avantage procuré au professionnel, si :</li>
    <li class="font-semibold">1° Le professionnel refuse de mettre la Plateforme en conformité ;</li>
    <li class="font-semibold">2° La mise en conformité de la Plateforme est retardée de manière injustifiée ;</li>
    <li class="font-semibold">3° La mise en conformité de la Plateforme ne peut intervenir sans frais imposés à
    l’Utilisateur ;</li>
    <li class="font-semibold">4° La mise en conformité de la Plateforme occasionne un inconvénient majeur pour
    l’Utilisateur ;</li>
    <li class="py-4 font-semibold">5° La non-conformité de la Plateforme persiste en dépit de la tentative de mise en
    conformité du professionnel restée infructueuse.</li>
    <li class="py-4 font-semibold">L’Utilisateur a également droit à une réduction du prix le cas échéant, à la résolution
du contrat, et/ou à la restitution de tout avantage procuré au professionnel, lorsque le
défaut de conformité est si grave qu&#39;il justifie que la réduction du prix ou la résolution
du contrat soit immédiate. L’Utilisateur n&#39;est alors pas tenu de demander la mise en
conformité de la Plateforme au préalable.</li>
    <li class="py-4 font-semibold">Dans les cas où le défaut de conformité est mineur, l’Utilisateur n&#39;a droit à l&#39;annulation
    du contrat que si le contrat ne prévoit pas le paiement d&#39;un prix.</li>
    <li class="py-4 font-semibold">Toute période d&#39;indisponibilité de la Plateforme en vue de sa remise en conformité
suspend la garantie qui restait à courir jusqu&#39;à la fourniture de la Plateforme de
nouveau conforme.</li>
    <li class="py-4 font-semibold">Ces droits résultent de l’application des articles L. 224-25-1 à L. 224-25-31 du code de la
    consommation.</li>
    <li class="py-4 font-semibold">Le professionnel qui fait obstacle de mauvaise foi à la mise en oeuvre de la garantie
légale de conformité encourt une amende civile d&#39;un montant maximal de 300 000
euros, qui peut être porté jusqu&#39;à 10 % du chiffre d&#39;affaires moyen annuel (article L.
242-18-1 du code de la consommation).</li>
    <li class="font-semibold">L’Utilisateur bénéficie également de la garantie légale des vices cachés en application
des articles 1641 à 1649 du code civil, pendant une durée de deux ans à compter de la
découverte du défaut. Cette garantie donne droit à une réduction de prix le cas
échéant si le contenu numérique ou le service numérique est conservé, ou à un
remboursement intégral contre renonciation au contenu numérique ou au service
numérique.</li>
<li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            13. Mises à jour
            </h3>
        </div></li>
    <li class="py-4 font-semibold">La présente clause est applicable uniquement entre WISUCINE et les Utilisateurs
ayant la qualité de Consommateur ou de « non-professionnel » au sens du Code de la
consommation.</li>
<li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            13.1 Mises à jour nécessaires au maintien de la conformité
            </h3>
        </div></li>
    <li class="py-4 font-semibold">Conformément à l’article L. 224-25-25 du Code de la consommation, nous vous
informerons des mises à jour nécessaires au maintien de la conformité de la
Plateforme et nous vous les fournirons le cas échéant.</li>
    <li class="font-semibold">Si vous n’installez pas ces mises à jours dans un délai raisonnable, nous ne serons pas
responsables des défauts de conformité résultant uniquement de la non-installation
des mises à jour, à condition que :</li>
    <li class="font-semibold">- vous ayez été informé de la disponibilité des mises à jour et des conséquences en
    cas de non-installation ;</li>
    <li class="font-semibold">- la non-installation des mises à jour ne soit pas due à des lacunes dans les
    instructions d’installation que nous vous avons fournies.</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            13.2 Mises à jour non-nécessaires au maintien de la conformité
            </h3>
        </div></li>
    <li class="py-4 font-semibold">Nous pourrons proposer des mises à jour non-nécessaires au maintien de la
conformité de la Plateforme ou les mettre en oeuvre automatiquement afin
d’améliorer ou d’optimiser la Plateforme ou l’une de ses fonctionnalités.</li>
    <li class="py-4 font-semibold">Dans ce cas, nous vous informerons à l’avance de la mise à jour envisagée et de la
    date à laquelle elle interviendra.</li>
    <li class="py-4 font-semibold">Vous êtes en droit de refuser la mise à jour ou, le cas échéant, de la désinstaller, si la
mise à jour a une incidence négative sur votre accès ou sur votre utilisation de la
Plateforme.</li>
<li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            14. Traitements de données à caractère personnel
            </h3>
        </div></li>
    <li class="py-4 font-semibold">Le fonctionnement de la Plateforme et la fourniture de nos Services impliquent la
collecte et le traitement de données à caractère personnel vous concernant. Ces
traitements sont effectués conformément à la législation applicable et notamment le
RGPD et la loi du 6 janvier 1978 « Informatique et Libertés ».</li>
    <li class="py-4 font-semibold">Nous vous invitons à consulter notre Politique de confidentialité accessible sur la
Plateforme dans laquelle nous vous expliquons en détail les traitements de données
personnelles que nous réalisons.</li>
<li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            15. Liens hypertextes
            </h3>
        </div></li>
    <li class="py-4 font-semibold">La Plateforme peut contenir des liens hypertextes vers d’autres sites présents sur le
    réseau internet. Ces liens peuvent vous faire quitter la Plateforme.</li>
    <li class="py-4 font-semibold">Les sites vers lesquels vous pouvez être redirigés ne sont pas édités par WISUCINE.
    Aussi nous ne sommes en aucun cas responsable de leur contenu.</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            16. Service client - Réclamation
            </h3>
        </div></li>
    <li class="py-4 font-semibold">Vous pouvez nous contacter à tout moment pour tout renseignement sur nos Services ou pour nous faire part de vos réclamations via le formulaire de contact sur la Plateforme ou par email à l’adresse indiquée en tête des présentes.</li>
    <li class="py-4 font-semibold">Nous ferons nos meilleurs efforts pour répondre à vos demandes dans les meilleurs.</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            17. Suspension ou restriction d’accès à la Plateforme
            </h3>
        </div></li>
    <li class="py-4 font-semibold">En cas de manquement contractuel de votre part, nous pouvons suspendre ou restreindre votre accès à la Plateforme.</li>
    <li class="py-4 font-semibold">Nous pouvons suspendre ou restreindre votre accès en cas de violation de votre part de l’une quelconque de vos obligations contractuelles, et notamment en cas de violation des obligations prévues à l’article 10 des présentes.</li>
    <li class="py-4 font-semibold">Lorsque vous utilisez la Plateforme dans le cadre de votre activité commerciale ou professionnelle, la décision de suspension ou de restriction d’accès vous sera notifiée par email accompagnée d’un exposé des motifs afin vous permettre présenter vos observations, conformément au règlement UE 2019/1150 dit « Platform to Business ».</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            18. Résiliation des CGU
            </h3>
        </div></li>
        <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            18.1 Résiliation par WISUCINE
            </h3>
        </div></li>
    <li class="py-4 font-semibold">En cas de manquement contractuel grave ou répété de votre part, nous pouvons résilier les CGU.</li>
    <li class="font-semibold">Nous pouvons résilier les CGU notamment pour les motifs suivants :</li>
    <li class="font-semibold">- commission d’un comportement prohibé par l’article 10.3 ;</li>
    <li class="font-semibold">- non-respect grave ou répété d’une obligation des présentes constituant un motif valable de suspension ou restriction d’accès à la Plateforme.</li>
    <li class="font-semibold">Conformément au règlement UE 2019/1150 dit « Platform to Business », lorsque vous utilisez la Plateforme dans le cadre de votre activité commerciale ou professionnelle :</li>
    <li class="font-semibold">- la décision de résiliation vous sera notifiée par email au moins 30 jours avant que la résiliation ne prenne effet ;</li>
    <li class="font-semibold">- la décision sera accompagnée d’un exposé des motifs afin de vous permettre de présenter vos observations.</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            18.2 Résiliation par l’Utilisateur
            </h3>
        </div></li>
    <li class="font-semibold">Vous pouvez résilier les CGU à tout moment en nous le signalant par email à l’adresse indiquée en tête des présentes ou par l’intermédiaire de la fonctionnalité de résiliation en ligne de la Plateforme. Si vous avez souscrit un Abonnement Premium, vous ne pourrez pas résilier les CGU pendant la durée de l’abonnement, sauf faute contractuelle de notre part.</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            18.3 Conséquences de la cessation de la relation contractuelle
            </h3>
        </div></li>
    <li class="font-semibold">La cessation des CGU entraîne :</li>
    <li class="font-semibold">- la suppression immédiate de votre Profil et de vos Annonces sur la Plateforme ;</li>
    <li class="font-semibold">- la clôture définitive de votre Compte Utilisateur ;</li>
    <li class="font-semibold">- le cas échéant, l’inéligibilité de votre projet aux offres de mécénat proposées par WISUCINE et ses partenaires (rubrique « solidarité ») ;
    </li>
    <li class="font-semibold">- la fin de votre Abonnement Premium le cas échéant. Lorsque vous avez souscrit un Abonnement Premium et que celui-ci est résilié pour faute de votre part, alors vous serez tenu de payer l’intégralité de votre abonnement comme s’il s’était poursuivi jusqu’à son terme. Si votre abonnement est résilié pour une autre cause, alors vous serez uniquement tenu au paiement de votre abonnement à proportion de votre utilisation effective de la Plateforme. En toute hypothèse, vous avez la possibilité, dans les 30 jours calendaires de la cessation des CGU, de nous demander la restitution de vos Données dans un format standard, structuré, couramment utilisé et lisible par machine (tel que .xlsx, .csv, .xml). Dans ce cas, nous aurons 90 jours calendaires pour vous les mettre à disposition, sous forme de téléchargement. Vous disposerez alors de 30 jours calendaires pour télécharger vos Données, vérifier leur intégrité et éventuellement émettre des réserves. Au-delà de ce délai, et sans réserve de votre part, vous nous autorisez à détruire l’ensemble des copies de vos Données de notre système d’information, sans pouvoir nous le reprocher ultérieurement</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            19. Notre responsabilité
            </h3>
        </div></li>
        <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            19.1 Obligation générale de moyens
            </h3>
        </div></li>
    <li class="py-4 font-semibold">D’un commun accord, les Parties conviennent que WISUCINE est soumise à une obligation générale de moyens dans le cadre de la fourniture des Services.</li>
    <li class="py-4 font-semibold">En particulier, nous ne nous engageons sur aucun volume de vente ni perspective de chiffre d’affaires quelconque que vous pourriez espérer en vous inscrivant sur notre Plateforme.</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            19.2 Limitation de responsabilité envers les Utilisateurs professionnels
            </h3>
        </div></li>
    <li class="py-4 font-semibold">La présente clause ne s’applique pas entre WISUCINE et les Utilisateurs ayant la qualité de Consommateur.</li>
    <li class="py-4 font-semibold">La responsabilité de WISUCINE ne pourra être engagée que pour les seuls dommages prévisibles dont elle est directement à l’origine, à savoir ceux qui pourraient résulter de la fourniture des Services ou de l’utilisation de la Plateforme, à l’exclusion de tout dommage indirect, dommages matériels ou immatériels consécutifs ou non, toute perte d’exploitation, perte de production, manque à gagner, perte de profit, perte de contrat, préjudice d’image, immobilisation de personnel ou d’équipements, et cela sans aucun engagement solidaire avec les tiers ayant concouru au dommage.</li>
    <li class="font-semibold">Aucune réclamation et/ou action de l’Utilisateur, pour quelque cause que ce soit, ne pourra être formulée et/ou engagée contre WISUCINE passé un délai de douze (12) mois après la survenance du fait sur lequel elle repose. Les Parties reconnaissent que les limitations de responsabilité stipulées ci-dessus opèrent entre elles une répartition raisonnable des risques et responsabilités au regard du contexte et des conditions des présentes.</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            20. Force majeure
            </h3>
        </div></li>
    <li class="py-4 font-semibold">Aucune des Parties ne pourra être tenue responsable vis-à-vis de l’autre de tout manquement à ses obligations si l’exécution des CGU est retardée ou empêchée en raison d’un cas de force majeure.</li>
    <li class="py-4 font-semibold">Sont qualifiés de cas de force majeure les évènements suivants, sans que cette liste ne soit limitative : actes de guerre, actes de terrorisme, émeutes, conflits du travail, grèves internes et externes, lock-out, catastrophes naturelles ou sanitaires, pandémie, incendie, dégâts des eaux, foudre, restrictions légales ou gouvernementales, actes d’autorités administratives, gouvernementales et judiciaires non imputables à une faute de la Partie défaillante, absence de fourniture d’énergie, arrêt partiel ou total du réseau internet ou de tous réseaux de communication.</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            21. Assurances
            </h3>
        </div></li>
    <li class="py-4 font-semibold">Vous déclarez être assuré pour votre responsabilité civile auprès d'une compagnie notoirement solvable pour toutes les conséquences des présentes.</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            22. Stipulation finales
            </h3>
        </div></li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            22.1 Modification des CGU
            </h3>
        </div></li>
    <li class="py-4 font-semibold">Nous pouvons faire évoluer nos CGU à tout moment en vous notifiant par écrit la nouvelle version applicable. Sauf délai supérieur indiqué par WISUCINE dans la notification, vous disposez d’un délai de préavis de (15) jours à compter de celle-ci pour déclarer par écrit votre refus de la nouvelle version des CGU par lettre recommandée ou par mail adressée aux coordonnées indiquées en tête des présentes.</li>
    <li class="py-4 font-semibold">En cas de refus de votre part, exprimé dans le délai de préavis accordé, les CGU seront résiliées.</li>
    <li class="py-4 font-semibold">En l’absence de refus de votre part pendant le préavis, nous considérerons que vous avez tacitement accepté nos nouvelles CGU.</li>
    <li class="py-4 font-semibold">Exceptionnellement, les modifications des CGU pourront entrer en vigueur immédiatement (sans préavis) si nous sommes soumis à une obligation légale ou règlementaire de modifier d’une manière qui ne permet pas de respecter le délai de préavis précité, ou si nous nous devons faire face à un danger imprévu et imminent le justifiant (risque de fraude, logiciels malveillants, spams, violation de données, risques en matière de cybersécurité).</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            22.2 Convention sur la preuve
            </h3>
        </div></li>
    <li class="py-4 font-semibold">Les Parties reconnaissent une valeur probante aux données informatisées échangées entre elles par tout moyen électronique et notamment par le biais de la Plateforme. Vous reconnaissez expressément : (1) la valeur contractuelle et la validité de toute acceptation réalisée depuis la Plateforme et l’opposabilité de toute action réalisée une fois connecté au moyen de vos identifiants (2) que les journaux de connexion de notre Plateforme font foi entre vous et nous. Les registres informatisés, conservés dans nos systèmes informatiques dans des conditions raisonnables de sécurité, sont considérés comme des preuves des communications intervenues entre les Parties.</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            22.3 Titre et indépendance des clauses
            </h3>
        </div></li>
    <li class="py-4 font-semibold">En cas de divergence ou incohérence entre l’un quelconque des titres figurant en tête des clauses des présentes CGU et les stipulations qu’elles contiennent, les titres seront déclarés inexistants.</li>
    <li class="py-4 font-semibold">Si une ou plusieurs clauses ou stipulations des présentes CGU sont tenues pour non valides ou déclarées comme telles en application de la loi, d’un règlement ou d’une décision définitive d’une juridiction compétente, les autres clauses et stipulations conserveront toute leur force et leur validité.</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            22.4 Non-renonciation
            </h3>
        </div></li>
    <li class="py-4 font-semibold">Le fait que l'une ou l'autre des Parties ne revendique pas l'application d'une clause quelconque des CGU ou acquiesce à son inexécution, que ce soit de manière permanente ou temporaire, ne pourra être interprété comme une renonciation par cette Partie aux droits qui découlent pour elle de ladite clause.</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            22.5 Non-cession
            </h3>
        </div></li>
    <li class="py-4 font-semibold">Vous vous interdisez de céder ou de transférer, de quelque manière que ce soit, tout ou partie de vos droits et obligations résultant des présentes sans avoir préalablement obtenu notre accord exprès et écrit</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            22.6 Loi applicable
            </h3>
        </div></li>
    <li class="py-4 font-semibold">Les CGU sont régies par la loi française.</li>
    <li class="py-4 font-semibold"><div class=" md:max-w-xl lg:max-w-3xl">
            <h3 class="mb-12 px-6 text-2xl text-customgold font-bold">
            22.7 Règlement des différents
            </h3>
        </div></li>
    <li class="py-4 font-semibold">▪ Litige entre WISUCINE et un Utilisateur ayant la qualité de Consommateur
    En cas de litige relatif aux présentes qui n’aurait pas pu être réglé directement avec WISUCINE, vous pourrez saisir la juridiction du lieu où vous demeuriez au moment de la conclusion des présentes ou de la survenance du fait dommageable.</li>
    <li class="py-4 font-semibold">▪ Litige entre WISUCINE et un Utilisateur ayant la qualité de professionnel
    En cas de litige relatif aux présentes qui n’aurait pas pu être réglé directement avec WISUCINE,, vous pourrez saisir uniquement la juridiction compétente du lieu d’établissement de WISUCINE (Tribunaux de Bobigny), même en cas de pluralité de défendeurs, d’action en référé ou d’appel en garantie.</li>
    <li class="font-semibold"></li>
    <li class="font-semibold"></li>
    <li class="font-semibold"></li>
     </ul>
</section>
</div>
   </div>
 );
};

export default CGU;