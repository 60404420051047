import React, { useState } from 'react';
import { useAuth } from "react-oidc-context";

import {
    Card,
    CardHeader,
    CardBody,
    Typography,
    Button,
  } from "@material-tailwind/react";

  import FooterCard from '../cards/FooterCard';
 

import ModalValid from '../utils/ModalValid';

export default function Post({ post = {}, onClickEdit, onClickDelete }) {

    const auth = useAuth();
    const [showModal, setShowModal] = useState(false);

    return (
        <>
        <Card className="w-full max-w-[48rem] flex items-center" shadow={true} >
            
            <CardHeader color="transparent" floated={false} shadow={false} className="mx-0 flex items-center gap-4 p-2 rounded-none border-b">
                <Button onClick={onClickEdit}>Editer</Button>
            </CardHeader>
            
            <CardBody>
                <img
                src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
                alt="card-image"
                className="h-full w-full object-cover"
                />
                <Typography variant="h4" color="blue-gray" className="mb-2">{post.title}</Typography>
                <Typography color="gray" className="mb-8 font-normal">{post.content}</Typography>
            </CardBody>
            
            <FooterCard/>
        </Card>

        {showModal ? <ModalValid text="Voulez-vous supprimer la publication ?" onClickYes={onClickDelete} onClickNo={() => {setShowModal(false)}} /> : null}
        </>
    );

};

