import React from 'react';

const Histoire = () => {

 return (
   
    <div class="text-center border-[4px] border-black w-84  border-customgold mx-auto">
    <h1 class=" mb-6 text-3xl font-bold text-customgold">Notre histoire</h1>

    <ul class=" text-center dark:text-white  mb-6">
  <li
    class=" border-b-2 border-neutral-100 py-4 dark:border-white/10 font-semibold">
    Wisucine, une plateforme en ligne qui transforme la manière dont les professionnels et les passionnés du cinéma se connectent et collaborent.
  </li>
  <li
    class=" border-b-2 border-neutral-100 py-4 dark:border-white/10 font-semibold">
    Fondée par une équipe passionnée de cinéma, Wisucine est née de la volonté de créer un espace dynamique où les talents du cinéma peuvent s&#39;épanouir, se rencontrer et donner vie à
leurs projets les plus audacieux.
  </li>
  <li
    class=" border-b-2 border-neutral-100 py-4 dark:border-white/10 font-semibold">
    À travers une interface intuitive et des fonctionnalités puissantes, Wisucine offre aux professionnels de l&#39;industrie cinématographique la possibilité de découvrir de nouveaux
talents, de former des équipes créatives et de concrétiser leurs visions artistiques.
  </li>
  <li
    class=" border-b-2 border-neutral-100 py-4 dark:border-white/10 font-semibold">
    Pour les amateurs de cinéma, Wisucine représente bien plus qu&#39;une simple plateforme : c&#39;est un véritable écosystème où la créativité est encouragée, où les rêves prennent vie et où les
collaborations fructueuses voient le jour.
  </li>
  <li
    class=" border-b-2 border-neutral-100 py-4 dark:border-white/10 font-semibold ">
    Rejoignez-nous sur Wisucine et faites partie d&#39;une communauté passionnée qui célèbre l&#39;art du cinéma, où chaque utilisateur a l&#39;opportunité de partager ses histoires, de trouver
l&#39;inspiration et de collaborer avec d&#39;autres passionnés du septième art.
  </li>
</ul>
</div>
   
 );
};

export default Histoire;