import React from 'react';

export default function Streaming({ title = "Film" }) {

    return (
        <div class="container mx-auto mt-12 mb-4">
            <div class="flex flex-col items-center mb-1 pb-8">
                <h1 className="text-3xl font-bold text-center text-customgold">
                     {title}
                </h1>
            </div>
            <video class="h-full w-full" controls >
                <source
                    src="../James_Damont_VFINAL1.mp4"
                    alt="Film James Damont"
                    type="video/mp4"
                />
            </video>
        </div>
    );

};

