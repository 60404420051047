import React from 'react';
import { useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";

import WisucineAPI from '../../api/WisucineAPI';
import { useAlerts } from '../../context/AlertsContext';
import PostForm from '../../components/posts/PostForm';

const CreatePost = () => {

    const auth = useAuth();
    const { addAlert } = useAlerts();
    let navigate = useNavigate();

    const handleSubmit = async (formData) => {
        try {
            const response = WisucineAPI.post('posts/', formData, {
                headers: { Authorization: `Bearer ${auth.user?.access_token}` }
            });

            console.log('Form data submitted successfully:', response.data);

            addAlert({ severity: 'success', message: 'Annonce crée avec succès', timeout: 1000 });
            navigate('/posts/' + response.data?.newAd?.id);
        } catch (error) {
            console.error('Error submitting form data:', error);
            addAlert({ severity: 'error', message: 'Erreur lors de la création de l\'annonce', timeout: 5000 });
        }

    };

    return (
        <PostForm title="Créer une publication" onclickSubmit={handleSubmit} onClickCancel={() => navigate('/myposts')} />
    );

};

export default CreatePost;
