import React from 'react';

const Table = () => {

 return (
   <div class=" ">
    
    <div class="overflow-x-auto bg-white dark:bg-neutral-700 mb-8 mt-8">
    <h2 class="mb-6 text-center text-3xl font-bold text-customgold">Abonnements personalisés</h2>
  {/*!-- Table --*/}
  <table class="min-w-full text-center text-sm font-bold whitespace-nowrap ">

    {/*!-- Table head --*/}
    <thead class="uppercase tracking-wider border-b-2 dark:border-neutral-600 bg-neutral-50 dark:bg-neutral-800 border-t">
      <tr>
        <th scope="col" class="px-6 py-4  dark:border-neutral-600">
          
        </th>
        <th scope="col" class="px-6 py-4 border-x dark:border-neutral-600">
        <p class="mb-4 text-sm uppercase">
              <strong>Wisu free</strong>
            </p><br/>
        <h3 class="mb-6 text-3xl">
              <span class="text-3xl font-semibold">0</span>
                        <span class=" align-top">€ </span>
                        <span class=" font-medium">/ an</span>
            </h3>
        </th>
        <th scope="col" class="px-6 py-4 border-x dark:border-neutral-600">
        <p class="mb-4 text-sm uppercase">
              <strong>Wisu basic</strong>
            </p><br/>
            <h3 class="mb-6 text-3xl">
              <span class="text-3xl font-semibold">94,8</span>
                        <span class="text-black align-top">€ </span>
                        <span class="text-black font-medium">/ an</span>
                        <div class="text-sm semi-bold ">soit 7,9 €  / mois</div>
                         
                        
            </h3>
        </th>
        <th scope="col" class="px-6 py-4 border-x dark:border-neutral-600 bg-black">
        <p class="mb-4 text-sm uppercase text-customgold">
              <strong>WISU popular</strong>
            </p>
            <h3 class="mb-6 text-white text-3xl">
              <span class="text-3xl font-semibold">190,8</span>
                        <span class="text-white align-top">€ </span>
                        <span class="text-white font-medium">/ an</span>
                        <div class="text-sm text-white font-semibold">soit 15,9€ / mois</div>
                        <div class="text-sm text-white font-semibold">Prochainement</div>
                         
            </h3>
        </th>

        <th scope="col" class="px-6 py-4 border-x dark:border-neutral-600">
        <p class="mb-4 text-sm uppercase">
              <strong>WISU business</strong>
            </p>
            <h3 class="mb-6 text-3xl">
              <span class="text-3xl font-semibold">298,8</span>
                        <span class="align-top">€ </span>
                        <span class="font-medium">/ an</span>
                        <div class="text-sm font-semibold">soit 24,9€ / mois</div>
                        <div class="text-sm font-semibold">Prochainement</div>
             
            </h3>
        </th>
      </tr>
    </thead>

    {/*<!-- Table body -->*/}
    <tbody>

      <tr class="border-b dark:border-neutral-600 hover:bg-neutral-100 dark:hover:bg-neutral-600">
        <th scope="row" class="px-6 py-4 border-x dark:border-neutral-600">
        publications d’annonces
        </th>
        <td class="px-6 py-4 border-x dark:border-neutral-600">2/an</td>
        <td class="px-6 py-4 border-x dark:border-neutral-600">5/an</td>
        <td class="px-6 py-4 border-x dark:border-neutral-600">15/an</td>
        <td class="px-6 py-4 border-x dark:border-neutral-600">illimité</td>
      </tr>

      <tr class="border-b dark:border-neutral-600 hover:bg-neutral-100 dark:hover:bg-neutral-600 bg-neutral-50 dark:bg-neutral-800">
        <th scope="row" class="px-6 py-4 border-x dark:border-neutral-600">
        Candidature aux annonces
        </th>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[25px] h-[25px] fill-[#D4A55C]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"></path>

</svg></td>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[25px] h-[25px] fill-[#D4A55C]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"></path>

</svg></td>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[25px] h-[25px] fill-[#D4A55C]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"></path>

</svg></td>

<td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[25px] h-[25px] fill-[#D4A55C]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"></path>

</svg></td>
      </tr>

      <tr class="border-b dark:border-neutral-600 hover:bg-neutral-100 dark:hover:bg-neutral-600">
        <th scope="row" class="px-6 py-4 border-x dark:border-neutral-600">
       Accès aux Profils
        </th>
        <td class="px-6 py-4 border-x dark:border-neutral-600">5*mois</td>
        <td class="px-6 py-4 border-x dark:border-neutral-600">illimité</td>
        <td class="px-6 py-4 border-x dark:border-neutral-600">illimité</td>
        <td class="px-6 py-4 border-x dark:border-neutral-600">illimité</td>
      </tr>

      <tr class="border-b dark:border-neutral-600 hover:bg-neutral-100 dark:hover:bg-neutral-600 bg-neutral-50 dark:bg-neutral-800">
        <th scope="row" class="px-6 py-4 border-x dark:border-neutral-600">
        Consultation au fil d’actualité
        </th>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[25px] h-[25px] fill-[#D4A55C]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"></path>

</svg></td>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[25px] h-[25px] fill-[#D4A55C]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"></path>

</svg></td>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[25px] h-[25px] fill-[#D4A55C]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"></path>

</svg></td>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[25px] h-[25px] fill-[#D4A55C]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"></path>

</svg></td>

      </tr>

      <tr class="border-b dark:border-neutral-600 hover:bg-neutral-100 dark:hover:bg-neutral-600">
        <th scope="row" class="px-6 py-4 border-x dark:border-neutral-600">
        Accès illimité au groupe de discutions
        </th>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[25px] h-[25px] fill-[#D4A55C]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"></path>

</svg></td>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[25px] h-[25px] fill-[#D4A55C]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"></path>

</svg></td>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[25px] h-[25px] fill-[#D4A55C]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"></path>

</svg></td>
<td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[25px] h-[25px] fill-[#D4A55C]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"></path>

</svg></td>
      </tr>

      <tr class="border-b dark:border-neutral-600 hover:bg-neutral-100 dark:hover:bg-neutral-600">
        <th scope="row" class="px-6 py-4 border-x dark:border-neutral-600">
        Accès à toutes les informations des profils ( mails, CV , Téléphones … )
        </th>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[26px] h-[26px] fill-[#000000]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"></path>

</svg></td>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[26px] h-[26px] fill-[#000000]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"></path>

</svg></td>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[26px] h-[26px] fill-[#000000]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"></path>

</svg></td>
         <td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[25px] h-[25px] fill-[#D4A55C]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"></path>

</svg></td>
      </tr>

      <tr class="border-b dark:border-neutral-600 hover:bg-neutral-100 dark:hover:bg-neutral-600">
        <th scope="row" class="px-6 py-4 border-x dark:border-neutral-600">
         Accès aux évènements
        </th>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[26px] h-[26px] fill-[#000000]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"></path>

</svg></td>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[26px] h-[26px] fill-[#000000]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"></path>

</svg></td>
        <td class="px-6 py-4 border-x dark:border-neutral-600">-30%</td>
        <td class="px-6 py-4 border-x dark:border-neutral-600">-50%</td>
      </tr>

      <tr class="border-b dark:border-neutral-600 hover:bg-neutral-100 dark:hover:bg-neutral-600">
        <th scope="row" class="px-6 py-4 border-x dark:border-neutral-600">
        Accès masterclass
        </th>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[26px] h-[26px] fill-[#000000]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"></path>

</svg></td>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[26px] h-[26px] fill-[#000000]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"></path>

</svg></td>
        <td class="px-6 py-4 border-x dark:border-neutral-600">-30%</td>
        <td class="px-6 py-4 border-x dark:border-neutral-600">-50%</td>
      </tr>

      <tr class="border-b dark:border-neutral-600 hover:bg-neutral-100 dark:hover:bg-neutral-600">
        <th scope="row" class="px-6 py-4 border-x dark:border-neutral-600">
        place Wisupitch
        </th>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[26px] h-[26px] fill-[#000000]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"></path>

</svg></td>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[26px] h-[26px] fill-[#000000]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"></path>

</svg></td>
        <td class="px-6 py-4 border-x dark:border-neutral-600">1</td>
        <td class="px-6 py-4 border-x dark:border-neutral-600">3</td>
      </tr>

      <tr class="border-b dark:border-neutral-600 hover:bg-neutral-100 dark:hover:bg-neutral-600">
        <th scope="row" class="px-6 py-4 border-x dark:border-neutral-600">
        Création de groupes de discussion
        </th>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[26px] h-[26px] fill-[#000000]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"></path>

</svg></td>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[26px] h-[26px] fill-[#000000]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"></path>

</svg></td>
        <td class="px-6 py-4 border-x dark:border-neutral-600">1</td>
        <td class="px-6 py-4 border-x dark:border-neutral-600">illimité</td>
      </tr>

       

       

      <tr class="border-b dark:border-neutral-600 hover:bg-neutral-100 dark:hover:bg-neutral-600">
        <th scope="row" class="px-6 py-4 border-x dark:border-neutral-600">
        Droit à 2 annonces urgentes
        </th>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[26px] h-[26px] fill-[#000000]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"></path>

</svg></td>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[26px] h-[26px] fill-[#000000]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"></path>

</svg></td>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[26px] h-[26px] fill-[#000000]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"></path>

</svg></td>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><svg class="mx-auto w-[25px] h-[25px] fill-[#D4A55C]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
<path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"></path>

</svg></td>
      </tr>

    </tbody>

    {/*<!-- Table footer -->*/}
    <tfoot class="border-t-2 dark:border-neutral-600">
      <tr>
        <th class="px-6 py-4 border-x dark:border-neutral-600"></th>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><button type="button"
              class="inline-block w-full rounded bg-customgold px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-customgold focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
              data-te-ripple-init data-te-ripple-color="light">
              JE M'ABONNE
            </button></td>
        <td class="px-6 py-4 border-x dark:border-neutral-600"><button type="button"
              class="inline-block w-full rounded bg-customgold px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-customgold focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
              data-te-ripple-init data-te-ripple-color="light">
              JE M'ABONNE
            </button></td>
        <td class="px-6 py-4 border-x dark:border-neutral-600">Prochainement disponible</td>
        <td class="px-6 py-4 border-x dark:border-neutral-600">Prochainement disponible</td>
      </tr>
    </tfoot>
  </table>

</div>
     
   </div>
 );
};

export default Table