import React, { useState } from 'react';
import { useAuth } from "react-oidc-context";

import ModalValid from '../utils/ModalValid';

export default function Annonce({ annonce, onClickEdit, onClickDelete }) {

    const auth = useAuth();
    const [showModal, setShowModal] = useState(false);

    return (
        <div class="container mx-auto px-10 my-10">
            <div class="flex flex-col items-center">
            <h3 class="text-xl uppercase font-bold leading-none text-customgold">{annonce.jobName}</h3>
        </div>
        <div class="mt-4 flex flex-col">
            <h3 class="my-1 text-xl uppercase font-bold leading-none text-customgold">Description</h3>
            <p class="text-base font-normal text-black">{annonce.description}</p>
        </div>
        <div class="mt-4 flex flex-col">
            <h3 class="my-1 text-xl uppercase font-bold leading-none text-customgold">Détails</h3>
            <p class="text-base font-normal text-black">Contrat: <strong>{annonce.typeOfContract}</strong></p>
            <p class="text-base font-normal text-black">Lieu: <strong>{annonce.location}</strong></p>
            <p class="text-base font-normal text-black">Remuneration: <strong>{annonce.remuneration}</strong></p>
        </div>
        <div class="mt-4 flex flex-col">
            <h3 class="my-1 text-xl uppercase font-bold leading-none text-customgold">Informations</h3>
            <p class="text-base font-normal text-black">Date et heure du dépôt: <strong>{Date(annonce.metadata?.created_at)}</strong></p>
            <p class="text-base font-normal text-black">Crée par: <strong>{annonce.metadata?.created_by}</strong></p>
        </div>

        {auth.isAuthenticated ?
        <div class="mt-10 text-center">
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" type='button' onClick={onClickEdit}>Editer</button>
            <button class="ml-10 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full" type='button' onClick={() => {setShowModal(true)}}>Supprimer</button>
        </div>
        : null }

        {showModal ? <ModalValid text="Voulez-vous supprimer l'annonce ?" onClickYes={onClickDelete} onClickNo={() => {setShowModal(false)}} /> : null}
        </div>
    );

};

