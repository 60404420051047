import '../index.css';
import JamesDamont from '../assets/video/JamesDamont.mp4'

 
export default function Carousel() {
  return (
    <div class="text-center text-customgold font-bold text-2xl my-24">
    <video  autoPlay="true" controls="true" muted="true">
      <source
        src={JamesDamont}
        alt="JamesDammont"
        className="h-30 w-full object-cover"
        type="video/mp4"
        
      />
       
    </video>
    <a href="/film">Voir le film</a>
    </div>

    
  );
}