import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { TailSpin } from 'react-loader-spinner'

import logo from '../../assets/image/logo.jpg'
import WisucineAPI from '../../api/WisucineAPI';

const AnnoncePage = () => {

   const [annonces, setAnnonces] = useState([]);
   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
        setIsLoading(true)
        WisucineAPI
            .get('annonces')
            .then((response) => {
                setAnnonces(response.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => setIsLoading(false));
    }, [])

   if (isLoading) return <TailSpin visible={true} height="80" width="80" color="#d4a55c" radius="1" wrapperClass="my-20 flex-col items-center" />

   return (

        <div class="containerAnnonceList mx-auto md:px-6 ">
            <div class="relative flex flex-col items-center">
                <div class="AnnonceListTitle">
                <h1 className="text-3xl font-bold text-center text-customgold">
                     Les Annonces
                </h1>
                </div>
                { annonces.map(annonce => ( 

                    <Link to={`/annonce/${annonce.id}`} class="my-2 w-full flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100">
                        <img class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg" src={logo} alt=""/>
                        <div class="flex flex-col justify-between p-4 leading-normal">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900">{annonce.jobName}</h5>
                            <p class="mb-3 font-normal text-gray-700">{annonce.description}</p>
                        </div>
                    </Link>

                )) 
                }
            </div>
        </div>
   );

};

export default AnnoncePage;