import React from 'react';
import { Link } from "react-router-dom";

export default function AnnonceCard({ annonce }) {

    return (
        <div class="max-w-sm p-6 bg-gray border-2 border-gray-200 rounded-lg shadow mt-4 mb-4">
            <div class="text-center">
                <h4 class="text-xl font-bold text-gray-200">{annonce.jobName}</h4>
            </div>
            <div class="py-1 border-b border-gray-200"></div>
            <div class="mt-5 mb-5 flex flex-col">
                <p class="text-base font-normal text-white">Contrat: <strong>{annonce.typeOfContract}</strong></p>
                <p class="text-base font-normal text-white">Lieu: <strong>{annonce.location}</strong></p>
                <p class="text-base font-normal text-white">Remuneration: <strong>{annonce.remuneration}</strong></p>
            </div>
            <div class="py-1 border-b border-gray-200"></div>
            <div class="mb-3">
                <div class="text-center justify-center">
                    <Link to={`/annonce/${annonce.id}`} class="text-lg text-customgold font-bold">Consulter</Link>
                </div>
            </div>
        </div>
    );

};

