import Activity from '../components/Activity'

const feelds = [
    { username: 'Roland Kayova', action: 'a ajouté une vidéo', date:'Vendredi 15 Juin 2024, 20:00', link:'/film' },
    { username: 'Roland Kayova', action: 'création de son compte', date:'Vendredi 10 Juin 2024, 15:00' },
];

const FeeldActivity = () => {
   
  return (
    <div class="container mx-auto md:px-6 bg-white">
        <section>
            <h2 class="my-12 text-3xl font-bold text-center text-customgold">
                Actualités
            </h2>

            <ol class="relative border-s border-gray-200 dark:border-gray-700">                  
              
                {feelds.map(feeld => (
                    <li class="mb-10 ms-6">  
                        <Activity username={feeld.username} action={feeld.action} date={feeld.date} link={feeld.link} />
                    </li>
                ))}
            </ol>

          </section>
      </div>
  )
}
export default FeeldActivity