import React, { useState, useEffect } from 'react';

export default function FormAnnonce({ title = "Créer une Annonce", annonce, onClickCancel, onclickSubmit }) {
    
    const [formData, setFormData] = useState({
        id: '',
        jobName: '',
        location: '',
        typeOfContract: '',
        description: '',
        remuneration: '',
    });

    useEffect(() => {
        if (annonce) {
            setFormData(annonce)
        }
    }, [annonce]);

    function handleChange(e) {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        onclickSubmit(formData)
    }

    return (

        <div class="container mx-auto mt-12 mb-4">
            <div class="flex flex-col items-center mb-1">
                <h1 className="text-3xl font-bold text-center text-customgold">
                     {title}
                </h1>
            </div>
            <div class="flex flex-col rounded-[20px] mx-auto p-4 bg-clip-border">
                <form onSubmit={handleSubmit}>

                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="title">
                            Nom du poste
                        </label>
                        <input class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" type='text' name='jobName' value={formData.jobName} onChange={handleChange}/>
                    </div>

                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="location">
                            Lieu de travail
                        </label>
                        <input class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" type='text' name='location' value={formData.location} onChange={handleChange}/>
                    </div>

                    <div class="mb-4">
                        <label for="typeOfContract" class="block text-gray-700 text-sm font-bold mb-2">Type de Contrat</label>
                        <select name="typeOfContract" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 " value={formData.typeOfContract} onChange={handleChange}>
                            <option selected="">-- Sélectionner le type de Contrat --</option>
                            <option value="CDI">CDI</option>
                            <option value="CDD">CDD</option>
                            <option value="Stage">Stage</option>
                            <option value="Interim">Interim</option>
                            <option value="Alternance">Alternance</option>
                            <option value="Bénévolat">Bénévolat</option>
                            <option value="Prestation pour un particulier">Prestation pour un particulier</option>
                        </select>
                    </div>

                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="remuneration">
                            Rémunération
                        </label>
                        <input class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" type='text' name='remuneration' value={formData.remuneration} onChange={handleChange}/>
                    </div>

                    <div class="mb-4">
                        <label for="description" class="block text-gray-700 text-sm font-bold mb-2">Description de la mission</label>
                        <textarea id="description" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" name='description' value={formData.description} onChange={handleChange}></textarea>
                    </div>

                    <div class="mb-4 text-center">
                        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" type='submit'>Envoyer</button>
                        <button class="ml-10 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full" type='button' onClick={onClickCancel}>Annuler</button>
                    </div> 
                </form>
            </div>  
        </div>  

    );

}
