import React from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import { useAuth } from "react-oidc-context";

import { useAlerts } from '../../context/AlertsContext';
import AnnonceForm from '../../components/annonces/AnnonceForm';

const CreateAnnonce = () => {

    const auth = useAuth();
    const { addAlert } = useAlerts();
    let navigate = useNavigate();

    const handleSubmit = async (formData) => {

        const config = {
            headers: { Authorization: `Bearer ${auth.user?.access_token}` }
        };

        try {
            const response = await axios.post('https://api.wisucine.com:8954/annonce', formData, config);

            console.log('Form data submitted successfully:', response.data);

            addAlert({ severity: 'success', message: 'Annonce crée avec succès', timeout: 1000 });
            navigate('/annonce/' + response.data?.newAd?.id);
        } catch (error) {
            console.error('Error submitting form data:', error);
            addAlert({ severity: 'error', message: 'Erreur lors de la création de l\'annonce', timeout: 5000 });
        }

    };

    return (
        <div>
            <AnnonceForm title="Créer une Annonce" onclickSubmit={handleSubmit} onClickCancel={() => navigate('/')} />
        </div>
    );

};

export default CreateAnnonce;
