 
import React from 'react'
 
export default function WhoAreUs() {
    return (
        
   <div>
<link rel="stylesheet" href="https://demos.creative-tim.com/notus-js/assets/styles/tailwind.css"/>
<link rel="stylesheet" href="https://demos.creative-tim.com/notus-js/assets/vendor/@fortawesome/fontawesome-free/css/all.min.css"/>



<section class="relative  bg-black">
<div class="relative pt-16 pb-32 flex content-center items-center justify-center h-30">
        <div class="absolute top-0 w-full h-full bg-center bg-cover" style={{
               
        }}>
          <span id="blackOverlay" class="w-full h-full absolute opacity-75 bg-black"></span>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
              <div class="pr-12">
                <h2 class="text-customgold font-semibold text-2xl mb-6">
                  Qui sommes-nous?
                </h2>
                 
              </div>
            </div>
          </div>
        </div>
        <div class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"  >
          
        </div>
      </div>
      <section class="pb-10 bg-black-200 -mt-24">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap">
            <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                <div class="px-4 py-5 flex-auto">
                  <div class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-10 mb-5 shadow-lg rounded-full bg-customgold">
                    <i class="fa fa-hourglass" aria-hidden="true"></i>
                  </div>
                  <h6 class="text-xl font-semibold">Notre histoire</h6>
                  <p class="mt-4 text-lg text-black-500">
                Wisucine, une plateforme en ligne qui transforme la manière dont les professionnels et les
passionnés du cinéma se connectent et collaborent...
                </p>
                <a href="/notre-histoire" class="text-lg text-customgold font-bold">Lire la suite</a>
                </div>
              </div>
            </div>
            <div class="w-full md:w-4/12 px-4 text-center">
              <div class="relative flex flex-col min-w-0 break-words bg-white w-full mt-11 shadow-lg rounded-lg">
                <div class="px-4 py-5 flex-auto">
                  <div class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-customgold">
                    <i class="fa fa-users" aria-hidden="true"></i>
                  </div>
                  <h6 class="text-xl font-semibold">Nos valeurs</h6>
                  <p class="mt-4 text-lg text-black-500 mb-5">
                  Égalité : Tous les membres sont traités avec respect et ont un accès équitable aux
opportunités..
                </p>
                <a href="nos-valeurs" class="text-lg text-customgold font-bold ">Lire la suite</a>
                </div>
              </div>
            </div>
            <div class="pt-6 w-full md:w-4/12 px-4 text-center">
              <div class="relative flex flex-col min-w-0 break-words bg-white w-full mt-6 shadow-lg rounded-lg">
                <div class="px-4 py-5 flex-auto">
                  <div class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-customgold">
                    <i class="fa fa-handshake" aria-hidden="true"></i>
                  </div>
                  <h6 class="text-xl font-semibold">Nos services</h6>
                  <p class="mt-4 text-lg text-black-500 mb-5">
                  - Création d’offres d’emplois via des annonces et des appels à projets.

                   - Connexion entre les professionnels...
                </p>
                <a href="/nos-services" class="text-lg text-customgold font-bold">Lire la suite</a>
                </div>
              </div>
            </div>
          </div>
            
</div>
      </section>
       
      </section>
   </div>

   
   
    )
     
  }
