import React from 'react';
import { useParams,  useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useAuth } from "react-oidc-context";

import { useAlerts } from '../../context/AlertsContext';
import Annonce from '../../components/annonces/Annonce';
import AnnonceForm from '../../components/annonces/AnnonceForm';
import WisucineAPI from '../../api/WisucineAPI';

const AnnoncePage = () => {

    let { id } = useParams();
    const auth = useAuth();
    const { addAlert } = useAlerts();
    let navigate = useNavigate();

    const [annonce, setAnnonce] = useState({});
    const [modeEdit, setModeEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        WisucineAPI
            .get('annonce/' + id)
            .then((response) => {
                console.log(response.data);
                setAnnonce(response.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => setIsLoading(false));
    }, [id, modeEdit])

    const handlePut = async (formData) => {

        const config = {
            headers: { Authorization: `Bearer ${auth.user?.access_token}` }
        };

        try {
            const response = await WisucineAPI.put('annonce/' + formData.id, formData, config);
            
            console.log('Form data submitted successfully:', response.data);

        addAlert({ severity: 'success', message: 'Annonce mis a jour avec succès', timeout: 1000 });
        setModeEdit(false)
        } catch (error) {
            console.error('Error submitting form data:', error);
            addAlert({ severity: 'error', message: 'Erreur lors de la mise a jour de l\'annonce', timeout: 5000 });
        }

    };

    const handleDelete = async (e) => {

        const config = {
            headers: { Authorization: `Bearer ${auth.user?.access_token}` }
        };

        try {
            const response = await WisucineAPI.delete('annonce/' + id, config);
            console.log('Form data submitted successfully:', response.data);
            addAlert({ severity: 'success', message: 'Annonce supprimée avec succès', timeout: 1000 });
            navigate('/');
        } catch (error) {
            console.error('Error submitting form data:', error);
            addAlert({ severity: 'success', message: 'Erreur lors de la suppression de l\'annonce', timeout: 5000 });
        }
    }

    if (isLoading) return <TailSpin visible={true} height="80" width="80" color="#d4a55c" radius="1" wrapperClass="my-20 flex-col items-center" />

    return (
        <div>
            { modeEdit ? 
            <AnnonceForm title="Modifier l'Annonce" annonce={ annonce } onclickSubmit={handlePut} onClickCancel={() => setModeEdit(false)}/> 
            : 
            <Annonce annonce={ annonce } onClickEdit={() => setModeEdit(true)} onClickDelete={handleDelete}/> 
            }
        </div>
    );

};

export default AnnoncePage;