import React from 'react';

const Services = () => {

 return (
   <div class="text-center w-100 border-[4px] border-customgold ">
    <h1 class=" mb-6 text-3xl font-bold text-customgold">Nos services</h1>

    <ul class="w-96 text-center dark:text-white mx-auto mb-6">
  <li
    class="w-full border-b-2 border-neutral-100 py-4 dark:border-white/10">
    Création d’offres d’emplois via des annonces et des appels à projets.
  </li>
  <li
    class="w-full border-b-2 border-neutral-100 py-4 dark:border-white/10">
    Connexion entre les professionnels du cinéma, de l'audiovisuel et du spectacle pour créer des synergies culturelles dans la ville.
  </li>
  <li
    class="w-full border-b-2 border-neutral-100 py-4 dark:border-white/10">
    Mise en relation avec des clients comme les centres socio-culturels,les écoles et les entreprises.
  </li>
  <li
    class="w-full border-b-2 border-neutral-100 py-4 dark:border-white/10">
    Mise en lumière des talents locaux avec des événements où ils peuvent présenter leurs projets à des investisseurs et des organismes.
  </li>
   
</ul>
     
   </div>
 );
};

export default Services;