import React from 'react';
import { Link } from "react-router-dom";

import logo from '../../assets/image/logo.jpg'

const PostList = ({ title = "Mes publications", messageEmpty = "Vous n'avez pas de publications", posts = [] }) => {

   return (

        <div class="mx-auto md:px-6 ">
            <div class="relative flex flex-col items-center">
                <div class="">
                    <h1 className="text-3xl font-bold text-center text-customgold">{title}</h1>
                </div>

                <div class="py-3">
                    { posts.length ? 
                        posts.map(post => ( 
                            <Link to={`/posts/${post._id}`} class="my-2 w-full flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100">
                                <img class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg" src={logo} alt=""/>
                                <div class="flex flex-col justify-between p-4 leading-normal">
                                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900">{post.title}</h5>
                                    <p class="mb-3 font-normal text-gray-700">{post.content}</p>
                                </div>
                            </Link>
                        ))
                        :
                        <h2>{messageEmpty}</h2>
                    }
                </div>
            </div>
        </div>
   );

};

export default PostList;